<template>
  <div>
    <div
      v-if="item.type && item.type === 2"
      style="display: flex; align-items: center"
    >
      <span class="from">从</span>
      <el-date-picker
        v-model="startTime"
        :type="format"
        size="medium"
        clearable
        placeholder="开始时间"
        :value-format="item.format"
        @change="queryListWithin"
        style="width: calc(50% - 30px)"
        prefix-icon="startTimeIcon"
      >
      </el-date-picker>
      <span class="to">至</span>
      <el-date-picker
        v-model="finishTime"
        :type="format"
        size="medium"
        clearable
        placeholder="结束时间"
        :value-format="item.format"
        @change="queryListWithin"
        style="width: calc(50% - 30px)"
        prefix-icon="startTimeIcon"
      >
      </el-date-picker>
      <el-dropdown trigger="click" @command="changeTime($event, true)">
        <span class="el-dropdown-link">
          <span><i class="el-icon-caret-bottom"></i></span>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            :command="item"
            v-for="(item, index) in timeOption"
            :key="index"
            >{{ item }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div v-else>
      <div
        v-if="disabled || item.code === 'Created' || item.code === 'Updated'"
        class="disabledCss"
      >
        {{ formData[item.code] ? formData[item.code] : "系统自动录入" }}
      </div>
      <el-date-picker
        v-else
        v-model="formData[item.code]"
        :type="format"
        size="medium"
        clearable
        prefix-icon="startTimeIcon"
        placeholder="选择时间"
        :value-format="item.format"
        @change="queryList"
        style="width: 100%"
      />
    </div>
  </div>
</template>

<script>
import Minxins from "../Minxins";
import moment from "moment";
export default {
  name: "DateTime",
  components: {},
  mixins: [Minxins],
  computed: {
    format() {
      switch (this.item.format) {
        case "yyyy-MM":
          return "month";
        case "yyyy-MM-dd":
          return "date";
        case "yyyy-MM-dd HH:mm:ss":
          return "datetime";
        default:
          return "datetime";
      }
    },
    startTime: {
      get() {
        if (this.formData[this.item.code]) {
          return this.formData[this.item.code][0];
        } else return null;
      },
      set(val) {
        if (!this.formData[this.item.code]) {
          this.$set(this.formData, this.item.code, []);
        }
        this.$set(this.formData[this.item.code], 0, val);
      },
    },
    finishTime: {
      get() {
        if (this.formData[this.item.code]) {
          return this.formData[this.item.code][1];
        } else return null;
      },
      set(val) {
        if (!this.formData[this.item.code]) {
          this.$set(this.formData, this.item.code, []);
        }
        this.$set(this.formData[this.item.code], 1, val);
      },
    },
  },
  data() {
    return {
      timeOption: [
        "当天",
        "昨天",
        "明天",
        "本周",
        "上周",
        "下周",
        "本月",
        "上月",
        "下月",
        "本季度",
        "本年度",
        "上年度",
        "下年度",
      ],
    };
  },
  
  methods: {
    changeTime(time, flag) {
      if (["当天", "昨天", "明天"].includes(time)) this.setDate(time);
      if (["本周", "上周", "下周"].includes(time)) this.setWeek(time);
      if (["本月", "上月", "下月"].includes(time)) this.setMonth(time);
      if (["本季度"].includes(time)) this.setQuarter(time);
      if (["本年度", "上年度", "下年度"].includes(time)) this.setYear(time);
      if (flag) {
        this.queryListWithin();
      } else {
        this.$set(this.formData, this.item.code, [
          this.startTime,
          this.finishTime,
        ]);
      }
    },
    setDate(time) {
      let date = new Date().getDate();
      switch (time) {
        case "当天":
          date;
          break;
        case "明天":
          date += 1;
          break;
        case "昨天":
          date -= 1;
          break;
      }
      let today = moment(new Date()).date(date).format("YYYY-MM-DD");
      this.startTime = this.format === "datetime" ? today + " 00:00:00" : today;
      this.finishTime =
        this.format === "datetime" ? today + " 23:59:59" : today;
    },
    setWeek(time) {
      let monday = null;
      let sunday = null;
      let weekOfDay = new Date().getDay(); // 今天星期几
      switch (time) {
        case "本周":
          monday = moment().weekday(1).format("YYYY-MM-DD"); //本周一
          sunday = moment().weekday(7).format("YYYY-MM-DD"); //本周日
          break;

        case "上周":
          monday = moment()
            .subtract(weekOfDay + 7 - 1, "days")
            .format("YYYY-MM-DD"); //上周一
          sunday = moment().subtract(weekOfDay, "days").format("YYYY-MM-DD"); //上周五
          break;
        case "下周":
          monday = moment()
            .add(7 - weekOfDay + 1, "d")
            .format("YYYY-MM-DD"); //下周一
          sunday = moment()
            .add(7 - weekOfDay + 7, "d")
            .format("YYYY-MM-DD"); //下周五
          break;
      }
      this.startTime =
        this.format === "datetime" ? monday + " 00:00:00" : monday;
      this.finishTime =
        this.format === "datetime" ? sunday + " 23:59:59" : sunday;
    },
    getDays(year, month) {
      const days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
        days[1] = 29;
      }
      return days[month];
    },
    setMonth(time) {
      let thisYear = new Date().getFullYear();
      let thisMonth = new Date().getMonth(); // 本月
      let startMonth = null;
      let endMonth = null;
      switch (time) {
        case "本月":
          startMonth = `${thisYear}-${
            thisMonth < 9 ? "0" + (thisMonth + 1) : thisMonth + 1
          }-01`;
          endMonth = `${thisYear}-${
            thisMonth < 9 ? "0" + (thisMonth + 1) : thisMonth + 1
          }-${this.getDays(thisYear, thisMonth)}`;
          break;

        case "上月":
          if (thisMonth === 0) {
            startMonth = `${thisYear - 1}-12-01`;
            endMonth = `${thisYear - 1}-12-31`;
          } else {
            startMonth = `${thisYear}-${
              thisMonth < 9 ? "0" + thisMonth : thisMonth + 1
            }-01`;
            endMonth = `${thisYear}-${
              thisMonth < 9 ? "0" + thisMonth : thisMonth + 1
            }-${this.getDays(thisYear, thisMonth - 1)}`;
          }
          break;
        case "下月":
          if (thisMonth === 11) {
            startMonth = `${thisYear + 1}-01-01`;
            endMonth = `${thisYear + 1}-01-31`;
          } else {
            startMonth = `${thisYear}-${
              thisMonth < 9 ? "0" + (thisMonth + 2) : thisMonth + 1
            }-01`;
            endMonth = `${thisYear}-${
              thisMonth < 9 ? "0" + (thisMonth + 2) : thisMonth + 1
            }-${this.getDays(thisYear, thisMonth + 1)}`;
          }
          break;
      }
      // console.log(startMonth, endMonth);
      this.startTime =
        this.format === "datetime" ? startMonth + " 00:00:00" : startMonth;
      this.finishTime =
        this.format === "datetime" ? endMonth + " 23:59:59" : endMonth;
    },

    setQuarter() {
      let thisYear = new Date().getFullYear();
      let thisMonth = new Date().getMonth(); // 本月
      let quarter = Math.ceil((thisMonth + 1) / 3);
      let startMonth = `${thisYear}-0${(quarter - 1) * 3 + 1}-01`;
      let endMonth = `${thisYear}-${
        quarter < 4 ? "0" + quarter * 3 : quarter * 3
      }-${this.getDays(thisYear, quarter * 3 - 1)}`;
      this.startTime =
        this.format === "datetime" ? startMonth + " 00:00:00" : startMonth;
      this.finishTime =
        this.format === "datetime" ? endMonth + " 23:59:59" : endMonth;
    },
    setYear(time) {
      let thisYear = new Date().getFullYear();
      switch (time) {
        case "本年度":
          thisYear;
          break;
        case "上年度":
          thisYear -= 1;
          break;
        case "下年度":
          thisYear += 1;
          break;
      }
      this.startTime =
        this.format === "datetime"
          ? `${thisYear}-01-01` + " 00:00:00"
          : `${thisYear}-01-01`;
      this.finishTime =
        this.format === "datetime"
          ? `${thisYear}-12-31` + " 23:59:59"
          : `${thisYear}-12-31`;
    },
    queryListWithin() {
      this.$set(this.formData, this.item.code, [
        this.startTime,
        this.finishTime,
      ]);
      this.$emit("queryList");
    },
  },
};
</script>

<style scoped lang="less">
.el-dropdown-menu {
  height: 200px;
  overflow: auto;
}
.el-dropdown {
  height: 34px;
  border: 1px solid #dcdfe6;
  border-left: unset;
  cursor: pointer;
}
.length {
  margin-top: 5px;
}
.length:nth-child(2) {
  color: #8c8c8c;
}

/deep/ .el-input--suffix .el-input__inner {
  padding-right: 0;
  border-radius: 0;
  padding-left: 15px;
}
/deep/ .startTimeIcon {
  display: none;
}
.to,
.from {
  display: inline-block;
  border-top: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  background-color: #f4f8fc;
  box-sizing: border-box;
  line-height: 34px;
  width: 24px;
  text-align: center;
}
.from {
  border-left: 1px solid #dcdfe6;
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import formData from './modules/formData'
import user from './modules/user'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    nodeMap: new Map(),
    selectedNode: {},
    selectFormItem: null,
    design: {},
    dataNameList: [],
    process: {},
    routerList: JSON.parse(localStorage.getItem('routerList')) || [],
    formRulesList: [],
  },
  mutations: {
    setFormRulesList(state, val) {
      state.formRulesList = val
    },
    selectedNode(state, val) {
      state.selectedNode = val
    },
    loadForm(state, val) {
      state.design = val
    },
    setDataNameList(state, val) {
      state.dataNameList = val
    },
    setProcess(state, val) {
      state.process = val
    },
    setRouterList(state, val) {
      state.routerList = val
      localStorage.setItem("routerList", JSON.stringify(val));
    }
  },
  modules: {
    user,
    formData
  },
  getters

})

export default store
<template>
  <el-select
    v-model.lazy="formData[item.code]"
    size="medium"
    clearable
    multiple
    placeholder="请选择"
    @change="queryList"
    style="width: 100%"
    :disabled="disabled"
    @focus="getList"
  >
    <el-option
      :value="item.status"
      :label="item.name"
      v-for="item in options"
      :key="item.status"
    >
    </el-option>
  </el-select>
</template>

<script>
import { getEnum } from "@/api/enum.js";
import Minxins from "../Minxins";
export default {
  name: "Status",
  mixins: [Minxins],
  data() {
    return {
      options: [],
    };
  },
  methods: {
    getList() {
      getEnum().then((res) => {
        this.options = res.data;
      });
    },
  },
};
</script>

<style scoped>
</style>

<template>
  <div>
    <div v-if="disabled" class="disabledCss">
      <div v-if="item.name === 'MultipleSelect'">
        {{
          formData[item.code]
            ? formData[item.code].join("，")
            : formData[item.code]
        }}
      </div>
      <div v-else>{{ formData[item.code] }}</div>
    </div>

    <el-radio-group
      v-else-if="!item.type && item.format === 'expanding'"
      v-model="formData[item.code]"
    >
      <el-radio
        v-for="(op, index) in item.options.split(',')"
        :key="index"
        :label="op"
        >{{ op }}</el-radio
      >
    </el-radio-group>

    <el-select
      v-model.lazy="formData[item.code]"
      size="medium"
      clearable
      :multiple="
        item.name === 'MultipleSelect' || (item.type && item.type === 2)
      "
      v-else
      placeholder="请选择"
      @change="queryList"
      style="width: 100%"
    >
      <el-option value="--" label="--(空值)" v-if="!item.itemLimit">
      </el-option>
      <el-option
        v-for="(op, index) in item.options.split(',')"
        :key="index"
        :value="op"
        :label="op"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
import Minxins from "../Minxins";
export default {
  name: "SelectInput",
  mixins: [Minxins],
};
</script>

<style scoped>
</style>

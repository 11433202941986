import { getPageOneInfo, getRelevanceList, getDrawerPageOption } from "@/api/drawerPage";
import router from "@/router";
import { getTime } from "@/utils/CustomUtil.js";
import {
    getFormFillList,
} from "@/api/process";
// 获取当前路由
const state = {
    tableData: {},
    pageNameList: [],
    multipleSelection: [],
    moduleList: [],
    btnPermission: [],
    externalBtnPermission: [],
    refresh: false,

    // 表单内数据详情
    drawerPageType: '',
    field: [],
    fieldData: {},
    approve: {},
    isVisibility: false,
    relevanceList: [],
    activeTabItems: {},
    formLoading: false,
    formData: {},
}
const mutations = {
    setFormLoading(state, val) {
        state.formLoading = val
    },
    setPageNameList(state, val) {
        if (val.type === 'add') {
            state.pageNameList.push(val.val)
        } else if (val.type === 'splice') {
            state.pageNameList.splice(val.val + 1)
            console.log(state.pageNameList)
        } else if (val.type === 'replace') {
            state.pageNameList.splice(val.val.index, 999, val.val)
        } else {
            state.pageNameList = [val]
        }
    },
    // 外面的表单
    setTableData(state, val) {
        state.tableData = val
        state.pageNameList = [val]
    },
    setMultipleSelection(state, val) {
        state.multipleSelection = val
    },
    setModuleList(state, val) {
        state.moduleList = val
    },
    setExternalBtnPermission(state, val) {
        state.externalBtnPermission = val
    },
    setBtnPermission(state, val) {
        state.btnPermission = val
    },
    setDrawerPageType(state, val) {
        state.drawerPageType = val
    },
    setIsVisibility(state, val) {
        state.isVisibility = val
    },
    setFieldData(state, val) {
        state.fieldData = val
    },
    setApprove(state, val) {
        state.approve = val
    },
    setRelevanceList(state, val) {
        state.relevanceList = val
    },
    setActiveTabItems(state, val) {
        state.activeTabItems = val
    },
    setRefresh(state, val) {
        state.refresh = val
    },
    setField(state, val) {
        state.field = val.sort((a, b) => {
            return a.position - b.position;
        });
    },
    setFormData(state, val) {
        state.formData = val
    },

}

const actions = {
    // 进入详情页面
    async getDetailData(context, data) {
        // 最后页面栈
        let viewPage = context.rootGetters.viewPage
        // 登陆人员
        let userInfo = context.rootGetters.userInfo
        let res = {}
        // 新增或者复制
        if (data.type === 'add' || data.type === 'copy') {
            res = await getDrawerPageOption(data.formId, 'add')
            context.commit('setField', res.data)
        } else {
            // 查看
            res = await getPageOneInfo(data.formId, data.id)
            res.data.data.formId = res.data && res.data.formId
            context.commit('setField', res.data.field)
        }
        // 复制情况下不需要重复复制data
        if (data.type !== 'copy') {
            context.commit('setFieldData', res.data.data || {})
        }
        // 流程
        context.commit('setApprove', res.data.approve || {})
        // 详情页面状态
        context.commit('setDrawerPageType', data.type)
        // 详情页面按钮权限
        context.commit('setBtnPermission', res.data.btns || [])
        router.push(
            {
                query: {
                    code: data.formId,
                    objId: data.id,
                },
            },
            () => { }
        );

        // 设置组件默认值
        let formData = {}
        context.state.field.forEach((item) => {
            if (item.name === "ImageUpload") item.value = [];
            if (item.name === "FileUpload") item.value = [];
            if (item.name === "TableList") item.value = [];
            if (context.state.drawerPageType === "add" || context.state.drawerPageType === "copy") {
                if (item.code === "CreatedBy") {
                    formData[item.code] = [
                        {
                            id: userInfo.id,
                            name: userInfo.name,
                            type: "user",
                            avatar: userInfo.avatar,
                        },
                    ]
                } else if (item.code === "OwnerId") {
                    formData[item.code] = [
                        {
                            id: userInfo.id,
                            name: userInfo.name,
                            type: "user",
                            avatar: userInfo.avatar,
                        },
                    ]
                } else if (item.code === "Created") {
                    let date = getTime(new Date());
                    formData[item.code] = date
                } else if (item.name === "MultipleSelect" && context.state.drawerPageType !== "copy") {
                    formData[item.code] = item.value ? item.value.split(",") : item.value
                } else if (item.code === "CreatedDept") {
                    formData[item.code] = [
                        {
                            id: userInfo.deptId,
                            name: userInfo.deptName,
                            avatar: null,
                            type: "dept",
                        },
                    ]
                } else if (item.name === "Relevance" && viewPage.relevance && context.state.activeTabItems.mappingItem === item.code) {
                    formData[item.code] = viewPage.relevance
                } else {
                    if (context.state.drawerPageType === "add") {
                        if (
                            item.name === "UserPicker" &&
                            item.value &&
                            item.value !== "null"
                        ) {
                            let info = JSON.parse(item.value);
                            if (info.name !== "当前用户") {
                                userInfo = info;
                            }
                            formData[item.code] = [
                                {
                                    id: userInfo.id,
                                    name: userInfo.name,
                                    type: "user",
                                    avatar: userInfo.avatar,
                                },
                            ]
                            getFormFillList({
                                formId: viewPage.formId,
                                itemId: item.id,
                            }).then((res) => {
                                let fillList = res.data.fillList;
                                fillList.forEach((fill) => {
                                    if (fill.thisItemCode) {
                                        switch (fill.thatItemId) {
                                            case 1:
                                                formData[fill.thisItemCode] = [
                                                    {
                                                        id: userInfo.deptId,
                                                        name: userInfo.deptName,
                                                        avatar: null,
                                                        type: "dept",
                                                    },
                                                ]
                                                break;
                                            case 2:
                                                formData[fill.thisItemCode] = userInfo.sex ? "男" : "女"
                                                break;
                                            case 3:
                                                formData[fill.thisItemCode] = userInfo.jobNum
                                                break;
                                            case 4:
                                                formData[fill.thisItemCode] = userInfo.position
                                                break;
                                            case 5:
                                                formData[fill.thisItemCode] = userInfo.entryDate.join('-')
                                                break;
                                        }
                                    }
                                });
                            });
                        } else if (item.value === "null") {
                            formData[item.code] = null
                        } else {
                            formData[item.code] = item.value
                        }
                    } else {
                        formData[item.code] = context.state.fieldData[item.code]
                        if (item.code === "SerialNumber") {
                            formData[item.code] = null
                        }
                    }
                }
            } else {
                formData[item.code] = context.state.fieldData[item.code]
            }
        });
        context.commit('setFormData', formData)
        // 开启页面
        // 编辑情况下设置关联列表
        try {
            if (data.type !== "add" && data.type !== 'copy') {
                let res = await getRelevanceList(
                    data.formId,
                    data.id
                )
                context.commit('setRelevanceList', res.data)
                context.commit('setActiveTabItems', res.data[0])
            }
        } finally {
            context.commit('setFormLoading', false)
            context.commit('setIsVisibility', true)
        }
        return res.data.data
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
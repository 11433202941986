import requestAdmin from '@/utils/requestAdmin.js'

// 获取列表和搜索字段
export function getItemViewList(id) {
    return requestAdmin({
        url: `/item-view/list/${id}`,
        method: 'get'
    })
}


// 跟新是否显示和排序
export function updateItemView(data) {
    return requestAdmin({
        url: `/item-view`,
        method: 'put',
        data
    })
}

// 获取列表和搜索字段
export function getPageViewList(data) {
    return requestAdmin({
        url: `form-data/list`,
        method: 'post',
        data
    })
}

// 获取列表和搜索字段
export function getCandidateList(formId, code, keyword) {
    return requestAdmin({
        url: `/form-data/candidate?formId=${formId}&code=${code}&keyword=${keyword}`,
        method: 'get',
    })
}

//列表导出
export function pageViewListExport(data) {
    return requestAdmin({
        url: `form-data/export`,
        method: 'post',
        data,
        responseType: 'blob',
        getContentDisposition: true,
    })
}

//下载导入模板
export function importTemplate(id) {
    return requestAdmin({
        url: `form-data/export/template?formId=${id}`,
        method: 'get',
        responseType: 'blob',
    })
}

//下载导入错误文件
export function pageViewListErrorReport(id) {
    return requestAdmin({
        responseType: 'blob',
        url: `form-data/export/errorReport`,
        method: 'get',
        params: {
            uuid: id
        },
    })
}


// 获取操作日志详情
export function getoperateLogDetail(dataId, formId) {
    return requestAdmin({
        url: `data-log/list`,
        method: 'get',
        params: {
            dataId,
            formId
        }
    })
}

// 编辑数据填充字段
export function formFillSaveOrUpdate(data) {
    return requestAdmin({
        url: `form-fill/saveOrUpdate`,
        method: 'post',
        data
    })
}

// 获取数据填充
export function getFormFillList(params) {
    return requestAdmin({
        url: `form-fill/list`,
        method: 'get',
        params
    })
}

// 获取文件临时路径
export function getTempUrl(fileId) {
    return requestAdmin({
        url: `file/getTempUrl`,
        method: 'get',
        params: {
            fileId: fileId
        }
    })
}
import { getLogin } from "@/api/login";
import { loginByPhone, isDDLogin } from "@/api/setProcess.js";
import router from "@/router";

const state = {
    token: localStorage.getItem('userToken') || '',
    userInfo: JSON.parse(localStorage.getItem('userinfo')) || {},
}
const mutations = {
    setToken(state, val) {
        localStorage.setItem("userToken", val);
        state.token = val
    },
    setUserInfo(state, val) {
        state.userInfo = val
        localStorage.setItem("userinfo", JSON.stringify(val));
    },
}
const actions = {
    // 正常登录
    async getToken(context, data) {
        // 获取token
        let token = await loginByPhone(data)
        if (token.code === 200) {
            context.commit("setToken", token.data);
            context.dispatch('getUserInfo')
        }
        return token
    },
    // 钉钉登录
    async isDDLogin(context, data) {
        let token = await isDDLogin(data)
        context.commit("setToken", token.data);
        context.dispatch('getUserInfo')
        return token.data
    },
    // 获取个人信息
    async getUserInfo(context) {
        // 获取个人信息
        let user = await getLogin()
        context.commit("setUserInfo", user.data);
    },
    // 退出登录
    logOut(context) {
        context.commit('setToken', '')
        context.commit('setUserInfo', {})
        localStorage.clear();
        router.push("/login")
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}

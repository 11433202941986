<template>
  <div class="table">
    <div class="searchComp" v-if="type !== 'add' && type !== 'copy'">
      <div style="display: flex">
        <el-select
          v-model="searchComp"
          placeholder="选择筛选字段"
          size="medium"
          @change="handleSearchComp"
          style="margin-right: 10px"
        >
          <el-option
            v-for="item in item.items.filter(
              (comp) =>
                !['Description', 'FileUpload', 'ImageUpload'].includes(
                  comp.name
                )
            )"
            :key="item.code"
            :label="item.title"
            :value="item.code"
          >
          </el-option>
        </el-select>

        <!-- 单行文本和多行文本 -->
        <el-input
          placeholder="请输入内容"
          v-model="searchVal"
          clearable
          size="medium"
          style="width: 300px"
          @change="filterSearchVal"
          v-if="['TextInput', 'TextareaInput'].includes(searchCompObj.name)"
        >
        </el-input>

        <!-- 关联单选和关联多选 -->
        <el-select
          v-if="['Relevance', 'MultipleRelevance'].includes(searchCompObj.name)"
          v-model="searchVal"
          size="medium"
          clearable
          placeholder="请选择"
          multiple
          @change="filterSearchVal"
          style="width: 300px"
          value-key="Id"
          filterable
          remote
          reserve-keyword
          :remote-method="remoteMethod"
          @focus="handleFocus"
        >
          <el-option
            v-for="op in options"
            :key="op.Id"
            :value="op"
            :label="op.Name"
          ></el-option>
        </el-select>

        <!-- 单选下拉和多选下拉 -->
        <el-select
          v-model="searchVal"
          clearable
          size="medium"
          style="width: 300px"
          multiple
          placeholder="请选择"
          @change="filterSearchVal"
          v-if="['SelectInput', 'MultipleSelect'].includes(searchCompObj.name)"
        >
          <el-option
            v-for="(item, i) in searchCompObj.options.split(',')"
            :key="item + i"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>

        <!-- 数字输入 -->
        <div style="width: 400px" v-if="searchCompObj.name === 'AmountInput'">
          <span class="from">从</span>
          <el-input-number
            style="width: calc(50% - 24px)"
            :controls="false"
            controls-position="right"
            :precision="searchCompObj.format * 1"
            size="medium"
            clearable
            v-model="startNumber"
            placeholder="开始数值"
            @change="queryListWithin"
          />
          <span class="to">至</span>
          <el-input-number
            style="width: calc(50% - 24px)"
            :controls="false"
            controls-position="right"
            :precision="searchCompObj.format * 1"
            size="medium"
            clearable
            v-model="finishNumber"
            placeholder="结束数值"
            @change="queryListWithin"
          />
        </div>

        <!-- 人员选泽和部门选择 -->
        <div
          style="
            border: 1px solid #dcdfe6;
            cursor: pointer;
            width: 300px;
            height: 34px;
            margin-top: 2px;
          "
          class="org-picker"
          v-if="
            [
              'UserPicker',
              'MultipleUserPicker',
              'DeptPicker',
              'MultipleDeptPicker',
            ].includes(searchCompObj.name)
          "
          @click="handleOpenPicker"
        >
          <div
            style="
              text-align: center;
              line-height: 35px;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
            "
          >
            <el-button
              icon="el-icon-user"
              size="mini"
              v-show="!searchVal || searchVal.length < 1"
              round
              @click="$refs.orgPicker.show()"
              >选择{{
                searchCompObj.name === "MultipleUserPicker" ||
                searchCompObj.name === "UserPicker"
                  ? "人员"
                  : "部门"
              }}</el-button
            >
            <org-picker
              :type="
                searchCompObj.name === 'MultipleUserPicker' ||
                searchCompObj.name === 'UserPicker'
                  ? 'user'
                  : 'dept'
              "
              multiple
              ref="orgPicker"
              :selected="searchVal"
              @ok="selected"
            />
            <!-- <span class="placeholder"> 请选择人员 </span> -->
            <div style="display: flex; flex-wrap: wrap">
              <el-tag
                size="mini"
                style="margin: 5px"
                closable
                v-for="(dept, i) in searchVal"
                @close="delDept(i)"
                :key="i"
                >{{ dept.name }}</el-tag
              >
            </div>
          </div>
        </div>

        <!-- 时间选择 -->
        <div
          style="width: 500px"
          v-if="['Created', 'Updated', 'DateTime'].includes(searchCompObj.name)"
        >
          <span class="from">从</span>
          <el-date-picker
            v-model="startNumber"
            :type="format"
            size="medium"
            clearable
            placeholder="开始时间"
            :value-format="searchCompObj.format"
            @change="queryListWithin"
            style="width: calc(50% - 30px)"
            prefix-icon="startTimeIcon"
          >
          </el-date-picker>
          <span class="to">至</span>
          <el-date-picker
            v-model="finishNumber"
            :type="format"
            size="medium"
            clearable
            placeholder="结束时间"
            :value-format="searchCompObj.format"
            @change="queryListWithin"
            style="width: calc(50% - 30px)"
            prefix-icon="startTimeIcon"
          >
          </el-date-picker>
        </div>
      </div>

      <div
        @click="switchTable"
        style="display: flex; align-items: center; cursor: pointer"
      >
        <img
          src="https://ybr-test.oss-cn-shanghai.aliyuncs.com/public/20240221111210/收起.png"
          alt=""
          v-if="switchIcon"
          style="width: 24px; height: 24px"
        />
        <img
          src="https://ybr-test.oss-cn-shanghai.aliyuncs.com/public/20240221110507/展开.png"
          alt=""
          v-else
          style="width: 24px; height: 24px"
        />
      </div>
    </div>

    <el-table
      size="medium"
      border
      :data="data"
      style="width: 100%; height: fit-content"
      max-height="520px"
    >
      <el-table-column
        type="index"
        label="序号"
        width="70"
        :index="indexMethod"
      ></el-table-column>
      <el-table-column
        v-for="(columns, index) in item.items"
        :prop="columns.code"
        :label="columns.title"
        :key="columns.code + index"
        :width="
          ['Created', 'Updated', 'DateTime'].includes(columns.code)
            ? '180px'
            : 'auto'
        "
        :min-width="'200px'"
        align="center"
        show-overflow-tooltip
      >
        <template #header="{ column }">
          <div>
            <span v-if="columns.itemLimit && columns.itemLimit.isRequire"
              >*</span
            >
            {{ column.label }}
          </div>
        </template>
        <template #default="{ row }">
          <component
            :is="columns.name"
            :item="columns"
            :formData="row"
            :type="type"
            @queryList="queryList"
          >
          </component>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        width="auto"
        label="操作"
        v-if="type !== 'see'"
      >
        <template #default="{ row, $index }">
          <el-button size="mini" type="text" @click="delRow($index, row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="bottom">
      <el-button
        size="small"
        icon="el-icon-plus"
        v-if="type !== 'see'"
        @click="addRow"
        >添加数据</el-button
      >
      <div v-else></div>
      <!-- :disabled="false" -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="10"
        layout="total,sizes, prev, pager, next"
        :total="filterData.length"
        :current-page.sync="currentPage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
// import components from "@/views/home/CompExport";
import TextInput from "./TextInput.vue";
import TextareaInput from "./TextInput.vue"; //多行文本
import DateTime from "./DateTime.vue";
import AmountInput from "./AmountInput.vue";
import SelectInput from "./SelectInput.vue";
import Status from "./Status.vue";
import MultipleSelect from "./SelectInput.vue";
import Relevance from "./Relevance.vue";
import MultipleRelevance from "./Relevance.vue";
import DeptPicker from "./DeptPicker.vue";
import UserPicker from "./UserPicker.vue";
import MultipleDeptPicker from "./DeptPicker.vue";
import MultipleUserPicker from "./UserPicker.vue";
import Description from "./Description.vue";
import ImageUpload from "./ImageUpload.vue";
import FileUpload from "./FileUpload.vue";
import SerialNumber from "./SerialNumber.vue";
import Minxins from "../Minxins";
import { flexColumnWidth } from "@/utils/CustomUtil.js";
import { getPageViewList } from "@/api/process";
import OrgPicker from "@/components/common/OrgPicker";
export default {
  name: "TableList",
  mixins: [Minxins],
  components: {
    TextInput,
    TextareaInput,
    DateTime,
    AmountInput,
    SelectInput,
    MultipleSelect,
    DeptPicker,
    UserPicker,
    Status,
    Description,
    ImageUpload,
    FileUpload,
    MultipleDeptPicker,
    MultipleUserPicker,
    Relevance,
    MultipleRelevance,
    SerialNumber,
    OrgPicker,
  },

  // watch: {
  //   item: {
  //     deep: true,
  //     handler() {
  //       this.item.items.forEach((item) => {
  //         let valToArr = [
  //           "MultipleDeptPicker",
  //           "MultipleUserPicker",
  //           "DeptPicker",
  //           "UserPicker",
  //           "FileUpload",
  //           "ImageUpload",
  //         ];
  //         if (!item.value && valToArr.includes(item.name)) {
  //           item.value = [];
  //         }
  //       });
  //     },
  //   },
  // },
  // props: {
  //   formOption: {
  //     type: Array,
  //     default: [],
  //   },
  // },
  computed: {
    field() {
      return this.$store.state.formData.field;
    },
    format() {
      switch (this.searchCompObj.format) {
        case "yyyy-MM":
          return "month";
        case "yyyy-MM-dd":
          return "date";
        case "yyyy-MM-dd HH:mm:ss":
          return "datetime";
        default:
          return "datetime";
      }
    },
  },
  created() {
    let valToArr = [
      "MultipleDeptPicker",
      "MultipleUserPicker",
      "DeptPicker",
      "UserPicker",
      "FileUpload",
      "ImageUpload",
    ];
    this.item.items.forEach((k) => {
      if (
        this.formData[this.item.code] &&
        this.formData[this.item.code].length > 0
      ) {
        this.formData[this.item.code].forEach((item) => {
          if (!item[k.code] && valToArr.includes(k.name)) {
            this.$set(item, k.code, []);
          }
        });
      }
    });
    this.handleCurrentChange(this.currentPage);
  },
  data() {
    return {
      data: [],
      currentPage: 1,
      pageNo: 10,
      searchComp: "",
      searchCompObj: {},
      searchVal: null,
      startNumber: undefined,
      finishNumber: undefined,
      relevanceObj: {},
      options: [],
      filterData: [],
      switchIcon: false,
    };
  },
  methods: {
    handleOpenPicker() {
      this.$refs.orgPicker.show();
    },
    selected(values) {
      this.searchVal = [...values];
      this.filterSearchVal(this.searchVal);
    },
    delDept(i) {
      this.searchVal.splice(i, 1);
      this.filterSearchVal(this.searchVal);
    },
    switchTable() {
      this.switchIcon = !this.switchIcon;
      this.field.forEach((item) => {
        if (item.code !== this.item.code) {
          this.$set(item, "showTable", !item.showTable);
        }
      });
    },
    remoteMethod(val) {
      this.handleOption({ Name: val });
    },
    handleFocus() {
      this.handleOption({});
    },
    async handleOption(searchParamsJson) {
      let res = await getPageViewList({
        pageNumber: 1,
        pageSize: 999,
        formId: this.searchCompObj.options,
        searchParamsJson,
      });
      this.options = res.data.data.records;
    },
    queryListWithin() {
      this.searchVal = [this.startNumber, this.finishNumber];
      this.filterSearchVal(this.searchVal);
    },
    filterSearchVal(val) {
      console.log(val);
      if (this.searchCompObj.name === "MultipleRelevance") {
        this.filterData = this.formData[this.item.code].filter(
          (item) =>
            item[this.searchCompObj.code] &&
            item[this.searchCompObj.code].find((k) => k.Id === val.Id)
        );
      } else if (this.searchCompObj.name === "Relevance") {
        this.filterData = this.formData[this.item.code].filter(
          (item) =>
            item[this.searchCompObj.code] &&
            val.find((k) => item[this.searchCompObj.code].Id === k.Id)
        );
      } else if (this.searchCompObj.name === "AmountInput") {
        if (!val[1]) {
          this.filterData = this.formData[this.item.code].filter(
            (item) => item[this.searchCompObj.code] >= (val[0] || 0)
          );
        } else {
          this.filterData = this.formData[this.item.code].filter(
            (item) =>
              item[this.searchCompObj.code] >= (val[0] || 0) &&
              item[this.searchCompObj.code] <= val[1]
          );
        }
      } else if (
        ["SelectInput", "MultipleSelect"].includes(this.searchCompObj.name)
      ) {
        this.filterData = this.formData[this.item.code].filter(
          (item) =>
            item[this.searchCompObj.code] &&
            val.find((k) => item[this.searchCompObj.code].includes(k))
        );
      } else if (
        ["TextInput", "TextareaInput"].includes(this.searchCompObj.name)
      ) {
        this.filterData = this.formData[this.item.code].filter(
          (item) =>
            item[this.searchCompObj.code] &&
            item[this.searchCompObj.code].includes(val)
        );
      } else if (
        ["Created", "Updated", "DateTime"].includes(this.searchCompObj.name)
      ) {
        if (!val[1]) {
          this.filterData = this.formData[this.item.code].filter(
            (item) =>
              (item[this.searchCompObj.code] &&
                new Date(item[this.searchCompObj.code]).getTime()) >=
              (new Date(val[0]).getTime() || 0)
          );
        } else {
          this.filterData = this.formData[this.item.code].filter(
            (item) =>
              (item[this.searchCompObj.code] &&
                new Date(item[this.searchCompObj.code]).getTime()) >=
                (new Date(val[0]).getTime() || 0) &&
              new Date(item[this.searchCompObj.code]).getTime() <=
                new Date(val[1]).getTime()
          );
        }
      } else if (
        [
          "UserPicker",
          "MultipleUserPicker",
          "DeptPicker",
          "MultipleDeptPicker",
        ].includes(this.searchCompObj.name)
      ) {
        this.filterData = this.formData[this.item.code].filter(
          (item) =>
            item[this.searchCompObj.code] &&
            item[this.searchCompObj.code].find((k) =>
              val.find((e) => e.name === k.name)
            )
        );
      }
      console.log(
        !val,
        !val.length,
        typeof val === "object" && !val.some((item) => item)
      );
      if (
        !val ||
        !val.length ||
        (typeof val === "object" && !val.some((item) => item))
      ) {
        console.log(1);
        this.filterData = this.formData[this.item.code];
      }
      this.currentPage = 1;
      this.data = this.filterData.slice(
        (this.currentPage - 1) * this.pageNo,
        this.currentPage * this.pageNo
      );
    },
    handleSearchComp(val) {
      this.searchVal = null;
      this.finishNumber = undefined;
      this.startNumber = undefined;
      let res = this.item.items.find((item) => item.code === val);
      this.searchCompObj = JSON.parse(JSON.stringify(res));
      this.handleCurrentChange(1);
      console.log(this.searchCompObj);
    },
    handleSizeChange(val) {
      this.pageNo = val;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(val) {
      if (this.formData[this.item.code]) {
        // console.log(val)
        this.filterData = [...this.formData[this.item.code]];
        this.data = this.filterData.slice(
          (val - 1) * this.pageNo,
          val * this.pageNo
        );
      }
    },
    //自适应宽度
    flexColumnWidth,
    indexMethod(index) {
      return (this.currentPage - 1) * this.pageNo + (index + 1);
    },
    delRow(i) {
      this.formData[this.item.code].splice(
        i + (this.currentPage - 1) * this.pageNo,
        1
      );
      if (this.data.length === 1) {
        this.currentPage -= 1;
      }
      this.handleCurrentChange(this.currentPage);
    },
    async addRow() {
      let row = {};
      let valToArr = [
        "MultipleDeptPicker",
        "MultipleUserPicker",
        "DeptPicker",
        "UserPicker",
        "FileUpload",
        "ImageUpload",
        "MultipleSelect",
      ];
      this.item.items.forEach((col) => {
        if (valToArr.includes(col.name)) this.$set(row, col.code, []);
        else this.$set(row, col.code, undefined);
      });
      console.log(this.formData[this.item.code]);
      let arr = [...this.formData[this.item.code]];
      arr.push(row);
      this.$set(this.formData, this.item.code, arr);
      console.log(arr, this.formData[this.item.code]);
      if (!this.currentPage) this.currentPage = 1;
      if (this.data.length === this.pageNo) {
        this.currentPage = Math.ceil(
          this.formData[this.item.code].length / this.pageNo
        );
      }
      this.handleCurrentChange(this.currentPage);
      // this.$set(this.formData, this.item.code, this.formData[this.item.code]);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.disabledCss span {
  white-space: nowrap;
}
/deep/ .el-button--mini,
.el-button--mini.is-round {
  padding: 6px 15px;
}
/deep/ .el-input__inner {
  text-align: left;
  border-radius: 0;
}
.to,
.from {
  display: inline-block;
  border-top: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  background-color: #f4f8fc;
  box-sizing: border-box;
  line-height: 34px;
  width: 24px;
  text-align: center;
}
.from {
  border-left: 1px solid #dcdfe6;
}
.searchComp {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
/deep/.el-table .cell {
  line-height: unset;
}
.el-table {
  box-sizing: content-box;
}
/deep/ .valid-error {
  .el-input__inner {
    border-color: #f56c6c;
  }
}
/deep/ .el-input.is-disabled .el-input__inner {
  color: #304265;
}

.choose {
  border: 1px dashed @theme-primary !important;
}
// .table {
//   overflow: auto;
// }

.table-column {
  padding: 5px;
  margin-bottom: 10px;
  border-left: 3px solid #409eff;
  border-radius: 5px;
  background: #fafafa;
  /deep/ .el-form-item {
    margin-bottom: 0;
    .el-form-item__label {
      height: 25px;
    }
  }
  .table-column-action {
    float: right;
    span {
      color: #afafaf;
      margin-right: 10px;
      font-size: 13px;
    }
    i {
      color: #afafaf;
      padding: 5px;
      font-size: large;
      cursor: pointer;
      &:hover {
        color: #666666;
      }
    }
  }
}

.l-drag-from {
  min-height: 50px;
  background-color: rgb(245, 246, 246);

  .l-form-item,
  li {
    cursor: grab;
    background: #ffffff;
    padding: 10px;
    border: 1px solid #ebecee;
    margin: 5px 0;
  }
}

.l-form-header {
  font-size: small;
  color: #818181;
  text-align: left;
  position: relative;
  background-color: #fff;

  p {
    position: relative;
    margin: 0 0 10px 0;

    span {
      position: absolute;
      left: -8px;
      top: 3px;
      color: rgb(217, 0, 19);
    }
  }

  .l-option {
    position: absolute;
    top: -10px;
    right: -10px;

    i {
      font-size: large;
      cursor: pointer;
      color: #8c8c8c;
      padding: 5px;

      &:hover {
        color: @theme-primary;
      }
    }
  }
}
.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

/deep/ .el-table {
  .el-table__body-wrapper {
    z-index: 2;
  }
  &__fixed-right::before,
  .el-table__fixed::before {
    background-color: transparent;
  }
}
</style>

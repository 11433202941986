import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

const viewport = {
  content: "width=device-width, initial-scale=1.0, user-scalable=no"
}

const router = new Router({
  // mode: 'history',
  //base: __dirname,
  routes: [
    {
      path: '/',
      redirect: '/home'
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import("@/views/login/index.vue"),
      meta: { title: 'YLab-Cloud', viewport: viewport },
    },
    {
      path: "/admin/design",
      name: "Design",
      component: () => import("@/views/admin/FormProcessDesign.vue"),
      meta: { title: '表单流程设计', viewport: viewport },
      redirect: '/admin/design/formSetting',
      children: [
        {
          path: "formSetting",
          name: "FormSetting",
          component: () => import("@/views/admin/layout/FormDesign.vue"),
          meta: { title: '表单设计' }
        }, {
          path: "processDesign",
          name: "ProcessDesign",
          component: () => import("@/views/admin/layout/ProcessDesign.vue"),
          meta: { title: '流程设计' }
        }, {
          path: "tableDesign",
          name: "TableDesign",
          component: () => import("@/views/admin/layout/TableDesign.vue"),
          meta: { title: '列表设计' }
        }, {
          path: "formProSetting",
          name: "FormProSetting",
          component: () => import("@/views/admin/layout/FormProSetting.vue"),
          meta: { title: '高级设置' }
        }
      ]
    },
    {
      path: "/home",
      name: "Home",
      component: () => import("@/views/home/index.vue"),
      meta: { title: '首页', viewport: viewport }
    },
    {
      path: "/detail",
      name: "Detail",
      component: () => import("@/views/detail/index.vue"),
      meta: { title: '详情', viewport: viewport }
    },
    {
      path: "/backlog",
      name: "Backlog",
      component: () => import("@/views/backlog/index.vue"),
      meta: { title: '待办', viewport: viewport }
    },
    {
      path: "/error_msg",
      name: "ErrorMsg",
      component: () => import("@/views/errorMsg/index.vue"),
      meta: { title: '报错信息', viewport: viewport }
    },
  ]
})

export default router;

<template>
  <div>
    <div
      class="disabledCss"
      v-if="(type === 'see' || item.code === 'CreatedBy') && !item.type"
    >
      <span v-for="op in formData[item.code]" :key="op.id">
        {{ op.name }}
      </span>
    </div>
    <div
      style="
        border: 1px solid #dcdfe6;
        cursor: pointer;
        width: 99%;
        border-radius: 4px;
      "
      v-else
      @click="handleOpenPicker"
    >
      <div style="text-align: center">
        <el-button
          icon="el-icon-user"
          size="mini"
          v-show="!formData[item.code] || formData[item.code].length < 1"
          round
          :disabled="disabled"
          style="border: 1px dashed #dcdfe6"
          @click="$refs.orgPicker.show()"
          >选择人员</el-button
        >
        <org-picker
          type="user"
          :multiple="
            item.name === 'MultipleUserPicker' || (item.type && item.type === 2)
          "
          ref="orgPicker"
          :selected="formData[item.code] || []"
          @ok="selected"
          :disabled="disabled"
        />
        <!-- <span class="placeholder"> 请选择人员 </span> -->
        <div style="display: flex; flex-wrap: wrap">
          <el-tag
            size="mini"
            style="margin: 5px"
            closable
            v-for="(dept, i) in formData[item.code]"
            @close="delDept(i)"
            :key="i"
            >{{ dept.name }}</el-tag
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrgPicker from "@/components/common/OrgPicker";
import Minxins from "../Minxins";
import { getFormFillList } from "@/api/process";
export default {
  name: "UserPicker",
  components: { OrgPicker },
  mixins: [Minxins],
  data() {
    return {};
  },
  methods: {
    handleOpenPicker() {
      if (this.disabled) return;
      this.$refs.orgPicker.show();
    },
    selected(values) {
      getFormFillList({
        formId: this.$route.query.code,
        itemId: this.item.id,
      }).then((res) => {
        let fillList = res.data.fillList;
        fillList.forEach((fill) => {
          if (fill.thisItemCode) {
            switch (fill.thatItemId) {
              case 1:
                this.$set(this.formData, fill.thisItemCode, [
                  {
                    id: values[0].deptId,
                    name: values[0].deptName,
                    avatar: null,
                    type: "dept",
                  },
                ]);
                break;
              case 2:
                this.$set(
                  this.formData,
                  fill.thisItemCode,
                  values[0].sex ? "男" : "女"
                );
                break;
              case 3:
                this.$set(this.formData, fill.thisItemCode, values[0].jobNum);
                break;
              case 4:
                this.$set(this.formData, fill.thisItemCode, values[0].position);
                break;
              case 5:
                this.$set(
                  this.formData,
                  fill.thisItemCode,
                  values[0].entryDate.join("-")
                );
                break;
            }
          }
        });
      });
      this.$set(this.formData, this.item.code, values);
      this.$emit("queryList");
    },
    delDept(i) {
      this.formData[this.item.code].splice(i, 1);
      this.$emit("queryList");
    },
  },
};
</script>

<style scoped lang="less">
.disabledCss {
  padding: 0 !important;
  span {
    margin: 4px 4px;
    height: 20px;
    line-height: 20px;
    cursor: pointer;
    border: 1px solid #e9e9eb;
    padding: 5px;
    color: #304265;
    border-radius: 5px;
    white-space: normal;
  }
}
</style>

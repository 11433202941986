<template>
  <div>
    <div v-if="item.type && item.type === 2">
      <span class="from">从</span>
      <el-input-number
        style="width: calc(50% - 24px)"
        :controls="false"
        controls-position="right"
        :precision="item.format * 1"
        size="medium"
        clearable
        v-model="startNumber"
        placeholder="开始数值"
        @change="queryListWithin"
      />
      <span class="to">至</span>
      <el-input-number
        style="width: calc(50% - 24px)"
        :controls="false"
        controls-position="right"
        :precision="item.format * 1"
        size="medium"
        clearable
        v-model="finishNumber"
        placeholder="结束数值"
        @change="queryListWithin"
      />
    </div>
    <div v-else>
      <div v-if="disabled" class="disabledCss">
        {{
          formData[item.code]
            ? formData[item.code]
                .toFixed(item.format * 1)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : "0.00"
        }}
      </div>
      <!-- 从input换成blur  目前没有遇到bug  -->
      <el-input-number
        style="width: 100%"
        :controls="false"
        controls-position="right"
        :precision="item.format * 1"
        size="medium"
        clearable
        :value="formData[item.code]"
        placeholder="请输入数值"
        @blur="handleQueryList"
        v-else
      />
    </div>
  </div>
</template>

<script>
import Minxins from "../Minxins";
export default {
  name: "AmountInput",
  mixins: [Minxins],
  data() {
    return {
      startNumber: undefined,
      finishNumber: undefined,
    };
  },
  methods: {
    queryListWithin() {
      this.$set(this.formData, this.item.code, [
        this.startNumber,
        this.finishNumber,
      ]);
      this.$emit("queryList");
    },
    handleQueryList(e) {
      // console.log(e.target.value, this.item.format);
      this.$set(this.formData, this.item.code, e.target.value || 0);
    },
  },                                                                                                                                                                                                                                                                 
};
</script> 

<style lang="less" scoped>
.chinese {
  color: #afadad;
  font-size: smaller;
}
/deep/ .el-input__inner {
  text-align: left;
  border-radius: 0;
}
.to,
.from {
  display: inline-block;
  border-top: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  background-color: #f4f8fc;
  box-sizing: border-box;
  line-height: 34px;
  width: 24px;
  text-align: center;
}
.from {
  border-left: 1px solid #dcdfe6;
}
</style>

<template>
  <div v-if="operateLogList && operateLogList.length > 0">
    <div
      v-for="(item, index) in operateLogList"
      :key="item.createName + index"
      class="operateLog"
    >
      <div class="name">
        <span class="createName">
          {{ item.createName }}
          <span
            style="
              margin-left: 10px;
              font-size: 12px;
              background-color: #E1E7F9;
              color: #364A61;
              padding: 3px 10px;
              border-radius:38px
            "
          >
            {{ item.type === 1 ? "新增" : "编辑" }}</span
          ></span
        >
        <span class="time">{{ item.created }}</span>
      </div>
      <div v-if="item.logDetails && item.logDetails.length > 0">
        <div
          v-for="(log, num) in item.logDetails"
          :key="log.itemName + num"
          class="log"
        >
          <div class="itemName">{{ log.itemName }}</div>
          <div class="value">
            <div class="afterValue" v-if="log.afterValue">
              {{ log.afterValue }}
            </div>
            <div class="beforeValue" v-if="log.beforeValue">
              <del>{{ log.beforeValue }}</del>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "operateLog",
  props: {
    operateLogList: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="less">
.operateLog {
  background: #f8fafd;
  width: 330px;
  height: 100%;
  padding: 5px;
  margin-top: 20px;
  border-radius: 5px;

  .name {
    display: flex;
    justify-content: space-between;
    margin: 0 20px 0 5px;

    .createName {
      font-size: 14px;
    }

    .time {
      font-size: 12px;
      color: #8893a7;
      margin-top: 5px;
    }
  }

  &:first-child {
    margin-top: 0;
  }

  .log {
    display: flex;
    margin: 8px 5px;
    padding-top: 6px;
    border-top: 1px solid rgb(243, 245, 248);

    .itemName {
      width: 100px;
      color: #8893a7;
      font-size: 14px;
    }

    .value {
      width: 200px;
      height: 100%;

      .afterValue,
      .beforeValue {
        border-radius: 5px;
        padding: 5px;
        word-wrap: break-word;
        background-color: rgb(225, 250, 239);
        font-size: 14px;
      }

      .afterValue {
        color: green;
      }

      .beforeValue {
        color: red;
        margin-top: 5px;
      }
    }
  }
}
</style>
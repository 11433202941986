import requestFlow from '@/utils/requestFlow.js'


// 添加流程
export function setProcess(
    formId, process
) {
    return requestFlow({
        url: `workflow/update`,
        method: 'post',
        data: {
            formId, process
        }
    })
}

// 获得流程
export function getProcess(
    formId
) {
    return requestFlow({
        url: `workflow/get`,
        method: 'post',
        data: {
            formId
        }
    })
}

// 表单同意/不同意
export function taskApprove(data) {
    return requestFlow({
        url: `task/approve`,
        method: 'post',
        data
    })
}

// 获取流程实例详情
export function getProcessDetail(instanceId) {
    return requestFlow({
        url: `instance/get`,
        method: 'post',
        data: {
            instanceId
        }
    })
}

// 是否钉钉登录
export function isDDLogin(code) {
    return requestFlow({
        url: `user/login?code=${code}`,
        method: 'get',
    })
}

// 钉钉跳转获取零时token
export function getLoginByTempCode(code) {
    return requestFlow({
        url: `user/loginByTempCode`,
        method: 'get',
        params: {
            tempCode:code
        }
    })
}

// 手机号登录
export function loginByPhone(data) {
    return requestFlow({
        url: `user/loginByPhone`,
        method: 'post',
        data
    })
}

// 流程催办
export function urgeFlow(data) {
    return requestFlow({
        url: `instance/urging`,
        method: 'post',
        data
    })
}

// 获取我的流程数量
export function getTodoNum(data) {
    return requestFlow({
        url: `instance/todoNum`,
        method: 'post',
        data
    })
}

// 获取所有我的流程
export function getTodoData(data) {
    return requestFlow({
        url: `instance/todoData`,
        method: 'post',
        data
    })
}

// 获取所有我的发起
export function getTodoMyData(data) {
    return requestFlow({
        url: `instance/myData`,
        method: 'post',
        data
    })
}
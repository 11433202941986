<template>
  <div>
    <el-cascader
      v-model="city"
      size="medium"
      v-if="item.type && item.type === 2"
      :options="options"
      :props="{
        value: 'name',
        label: 'name',
        children: 'district',
      }"
      style="width: 100%"
      clearable
    ></el-cascader>
    <div v-else>
      <div v-if="disabled" class="disabledCss">
        <div>
          {{ city.join("/") }}
        </div>
        <div>
          {{ cityDetail }}
        </div>
      </div>
      <div v-else>
        <el-cascader
          v-model="city"
          size="medium"
          :options="options"
        ></el-cascader>
        <i
          class="el-icon-location-outline"
          @click="mapVisible = true"
          style="font-size: 20px; margin-left: 10px; cursor: pointer"
        ></i>
        <el-input
          size="medium"
          style="margin-top: 10px"
          v-model="cityDetail"
          placeholder="请输入详细地址"
          show-word-limit
          :rows="2"
          type="textarea"
        />
      </div>
    </div>

    <el-dialog
      title="选择位置"
      :visible.sync="mapVisible"
      :fullscreen="false"
      :modal="false"
      id="mapDialog"
      :close-on-click-modal="false"
      width="768px"
    >
      <div style="margin-bottom: 10px">
        <label
          >关键词：
          <el-autocomplete
            style="width: 200px; margin-right: 20px"
            popper-class="autoAddressClass"
            :popper-append-to-body="false"
            v-model="keyword"
            :fetch-suggestions="querySearchAsync"
            :trigger-on-focus="false"
            placeholder="详细地址"
            @select="handleSelect"
            clearable
          >
            <template slot-scope="{ item }">
              <i class="el-icon-location fl mgr10" style="margin-top: 10px"></i>
              <div style="overflow: hidden">
                <div class="title">{{ item.title }}</div>
                <span class="address ellipsis">{{ item.address }}</span>
              </div>
            </template>
          </el-autocomplete>
        </label>

        <label
          >地区：<el-input
            size="mini"
            style="width: 200px"
            v-model="location"
            placeholder="请输入地区"
        /></label>
      </div>
      <baidu-map
        class="map"
        :center="center"
        :zoom="zoom"
        @ready="handlerMap"
        :scroll-wheel-zoom="true"
        @click="clickEvent"
        ak="wgvt68wWbjS3uerCDcTvz7q0qWRBccxD"
      >
        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
        <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT"></bm-city-list>
        <bm-geolocation
          anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
          :showAddressBar="true"
          :autoLocation="true"
          @locationSuccess="getLoctionSuccess"
        ></bm-geolocation>
        <bm-view
          :style="{ width: '100%', height: '300px', flex: 1, marginTop: '0px' }"
        ></bm-view>
        <bm-local-search
          :keyword="keyword"
          :auto-viewport="true"
          :selectFirstResult="true"
          :location="location"
          style="display: none"
        ></bm-local-search>
      </baidu-map>
      <div class="demo-input-suffix" style="margin-top: 2vh">
        <el-link type="info"> 位置：</el-link
        ><el-input
          style="width: 680px"
          size="mini"
          v-model="locData.address"
          :disabled="true"
        ></el-input>
      </div>
      <div slot="footer" class="dialog-footer" style="margin-top: -30px">
        <el-button
          type="warning"
          size="small"
          icon="el-icon-close"
          @click.native="mapVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          icon="el-icon-check"
          @click.native="findlocation"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import cityList from "@/assets/city-list.json";
import Minxins from "../Minxins";
import {
  BaiduMap,
  BmNavigation,
  BmView,
  BmGeolocation,
  BmCityList,
  BmLocalSearch,
} from "vue-baidu-map";

export default {
  name: "City",
  mixins: [Minxins],
  data() {
    return {
      options: cityList,
      mapVisible: false,
      location: "",
      keyword: "",
      center: { lng: 31.23592904, lat: 121.48053886 },
      zoom: 12,
      locData: {
        longitude: "",
        latitude: "",
        address: "",
      },
      addressComponents: {},
    };
  },
  components: {
    BaiduMap,
    BmView,
    BmNavigation,
    BmGeolocation,
    BmCityList,
    BmLocalSearch,
  },
  computed: {
    city: {
      get() {
        let city =
          (this.formData[this.item.code] &&
            this.formData[this.item.code].split("—")) ||
          [];
        return (city[0] && city[0].split("/")) || [];
      },
      set(val) {
        let str = this.item.searchs
          ? val.join("/")
          : `${val.join("/")}${this.cityDetail && "—" + this.cityDetail}`;
        this.$set(this.formData, this.item.code, str);
        this.$emit("queryList");
      },
    },
    cityDetail: {
      get() {
        let city =
          (this.formData[this.item.code] &&
            this.formData[this.item.code].split("—")) ||
          [];
        return city[1] || "";
      },
      set(val) {
        let str =
          this.item.type && this.item.type === 2
            ? this.city.join("/")
            : `${this.city.join("/")}${val && "—" + val}`;
        this.$set(this.formData, this.item.code, str);
        this.$emit("queryList");
      },
    },
  },

  methods: {
    handleSelect(item) {
      console.log(item);
      let arr = (item.city && item.address.split(item.city)) || [];
      let str = "";
      for (let i = 0; i < arr[1].length; i++) {
        const element = ["市", "县", "区"].find((k) => k === arr[1][i]);
        str = element;
        if (element) {
          let district = `${arr[1].split(str)[0]}${str}`;
          console.log(district);
          this.addressComponents = {
            ...item,
            district,
          };
          this.clickEvent(item);
          return;
        }
      }
      // this.addressComponents = item.address + item.title; //记录详细地址，含建筑物名
      // this.lon = item.point.lng
      // this.lat = item.point.lat//记录当前选中地址坐标
      // this.map.clearOverlays() //清除地图上所有覆盖物
      // this.mk = new BMap.Marker(item.point) //根据所选坐标重新创建Marker
      // map.addOverlay(this.mk) //将覆盖物重新添加到地图中
      // map.panTo(item.point) //将地图的中心点更改为选定坐标点
    },
    querySearchAsync(str, cb) {
      var options = {
        onSearchComplete: function (res) {
          //检索完成后的回调函数
          var s = [];
          if (local.getStatus() == BMAP_STATUS_SUCCESS) {
            for (var i = 0; i < res.getCurrentNumPois(); i++) {
              s.push(res.getPoi(i));
            }
            cb(s); //获取到数据时，通过回调函数cb返回到<el-autocomplete>组件中进行显示
          } else {
            cb(s);
          }
        },
      };
      var local = new BMap.LocalSearch(map, options); //创建LocalSearch构造函数
      local.search(str); //调用search方法，根据检索词str发起检索
    },
    handlerMap({ BMap, map }) {
      let _this = this; // 设置一个临时变量指向vue实例，因为在百度地图回调里使用this，指向的不是vue实例；
      let geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition(
        function (r) {
          console.log(r);
          _this.center = { lng: r.longitude, lat: r.latitude }; // 设置center属性值
          _this.autoLocationPoint = { lng: r.longitude, lat: r.latitude }; // 自定义覆盖物
          _this.addressComponents = r.address;
          _this.initLocation = true;
        },
        { enableHighAccuracy: true }
      );
      window.map = map;
    },
    //点击地图监听
    clickEvent(e) {
      map.clearOverlays();
      let Icon_0 = new BMap.Icon(
        "http://api0.map.bdimg.com/images/marker_red_sprite.png",
        new BMap.Size(64, 64),
        { anchor: new BMap.Size(18, 32), imageSize: new BMap.Size(36, 25) }
      );
      let myMarker = new BMap.Marker(new BMap.Point(e.point.lng, e.point.lat), {
        icon: Icon_0,
      });
      map.addOverlay(myMarker);
      //用所定位的经纬度查找所在地省市街道等信息
      let point = new BMap.Point(e.point.lng, e.point.lat);
      let gc = new BMap.Geocoder();
      let _this = this;
      gc.getLocation(point, function (rs) {
        console.log(rs);
        _this.addressComponents = rs.addressComponents;
        _this.locData.address = rs.address;
      });
      this.locData.longitude = e.point.lng;
      this.locData.latitude = e.point.lat;
    },
    //定位成功回调
    getLoctionSuccess(point) {
      map.clearOverlays();
      let Icon_0 = new BMap.Icon(
        "http://api0.map.bdimg.com/images/marker_red_sprite.png",
        new BMap.Size(64, 64),
        { anchor: new BMap.Size(18, 32), imageSize: new BMap.Size(36, 36) }
      );
      let myMarker = new BMap.Marker(
        new BMap.Point(point.point.lng, point.point.lat),
        { icon: Icon_0 }
      );
      map.addOverlay(myMarker);
      this.locData.longitude = point.point.lng;
      this.locData.latitude = point.point.lat;
      this.locData.address = `${point.addressComponent.city}/${point.addressComponent.province}`;
      this.addressComponents = point.addressComponent;
      console.log(this.locData, this.addressComponents);
    },
    //返回选中点的位置
    findlocation() {
      let str = `${this.addressComponents.province}${
        "/" + this.addressComponents.city
      }${
        this.addressComponents.district && "/" + this.addressComponents.district
      }${this.addressComponents.street && "—" + this.addressComponents.street}${
        this.addressComponents.streetNumber
      }`;
      console.log(str);
      this.$set(this.formData, this.item.code, str);
      this.mapVisible = false;
    },
    // handleCityVal() {
    //   let str = this.item.search
    //     ? this.city.join("/")
    //     : `${this.city.join("/")}-${this.cityDetail}`;
    //   console.log(str);
    //   this.$set(this.formData, this.item.code, str);
    //   this.$emit("queryList");
    // },
  },
};
</script>



// import xxx, {yyy} from '...' - xxx是默认导入， yyy 按需导入
import router from "@/router";
import store from "@/store";
import * as dd from "dingtalk-jsapi";
import { errorMsg } from "@/api/design";

const whiteList = ["/login", "/detail"];

let isPhone = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

let toPath = (token) => {
  if (process.env.NODE_ENV == "development") {
    window.location.href = `http://192.168.2.53:8080/#/pages/index/index?token=${token}`; // 开发环境
  } else if (process.env.NODE_ENV == "production" && window.location.href.includes("http://test")) {
    window.location.href = `http://test.cloud.yibeirui.com/h5/index.html#/pages/index/index?token=${token}`; // 测试环境
  } else {
    window.location.href = `http://cloud.yibeirui.com/h5/index.html#/pages/index/index?token=${token}`; // 生产环境
  }
};

//钉钉登陆清除本地缓存
if (dd.env.platform !== "notInDingTalk") {
  store.commit("user/setToken", "");
  store.commit("user/setUserInfo", {});
  localStorage.clear();
}
router.beforeEach(async (to, from, next) => {
  console.log(to.path);
  if (dd.env.platform !== "notInDingTalk") {
    if (isPhone && to.path === "/detail") {
      dd.getAuthCode({
        corpId: "ding8ade6b5e21085cf135c2f4657eb6378f",
        success: async function (res) {
          let token = await store.dispatch("user/isDDLogin", res.code);
          // this.toPath(token.data);
          window.location.href = `http://cloud.yibeirui.com/h5/index.html#/pages/list/listDetail?token=${token}&code=${to.query.code}&objId=${to.query.objId}`; // 生产环境
        },
        fail: function (err) {
          console.log(err, "err");
        },
      });
    }
  }
  const token = store.getters.token;
  if (token) {
    // 已登录
    if (isPhone ) {
      toPath(token);
    } else {
      if (to.path === "/login") {
        // 是登录页
        next("/");
      } else {
        next();
      }
    }
  } else {
    if (whiteList.includes(to.path)) {
      if (to.path === "/login" && isPhone ) {
        toPath(token);
      } else {
        next();
      }
    } else {
      if (dd.env.platform !== "notInDingTalk") {
        //进行钉钉登录操作
        dd.getAuthCode({
          corpId: "ding8ade6b5e21085cf135c2f4657eb6378f",
          success: async function (res) {
            let token = await store.dispatch("user/isDDLogin", res.code);
            errorMsg(`'钉钉登录', ${JSON.stringify(res)}`);
            // console.log(token)
            if (isPhone) {
              toPath(token);
            } else {
              next("/home");
            }
          },
          fail: function (err) {
            console.log(err, "err");
          },
        });
      } else {
        if (to.query.ddToken) {
          store.commit("user/setToken", to.query.ddToken);
          store.dispatch("user/getUserInfo");
          next(`/home?code=${to.query.code}`);
        } else {
          next("/login");
        }
      }
    }
  }
  if (to.meta.title && to.meta.title !== "首页") {
    document.title = to.meta.title;
  }
  if (to.meta.content) {
    let head = document.getElementByTagName("head");
    let meta = document.createElemnet("meta");
    meta.name = "viewport";
    meta.content = "width=device-width, initial-scale=1.0, user-scalable=no";
    head[0].appendChild(meta);
  }
});

<template>
  <div>
    <div style="display: flex; align-items: center; margin-bottom: 20px">
      <div
        style="width: 2px; height: 12px; background: #2e98fe; margin-right: 4px"
      ></div>
      <span style="font-size: 12px; color: #3d3d3d">流程节点</span>
      <el-popover
        placement="bottom"
        width="200"
        trigger="click"
        style="margin-left: auto"
        v-model="urge"
        v-if="flowLogList[0].nodeName !== '结束'"
      >
        <div
          style="font-size: 12px; color: #2e98fe; cursor: pointer"
          slot="reference"
        >
          <img
            src="https://ybr-test.oss-cn-shanghai.aliyuncs.com/public/images/容器@2x (11).png"
            alt=""
            style="width: 16px; height: 16px; vertical-align: middle"
          />
          一键催办
        </div>
        <div>
          <el-input type="textarea" v-model="urgeText" :rows="3"></el-input>
          <div style="margin-top: 10px; text-align: end">
            <el-button size="mini" @click="urge = false">取消</el-button>
            <el-button type="primary" size="mini" @click="handleUrge"
              >确定</el-button
            >
          </div>
        </div>
      </el-popover>
    </div>
    <div
      class="flowLogNode"
      v-for="(item, index) in flowLogList"
      :key="item.nodeCode + index"
      :style="{
        backgroundColor:
          item.status === 'NO_EXECUTE'
            ? '#FFF3E0'
            : item.status === 'DELIVER'
            ? '#F4F4F4'
            : item.status === 'READED' && item.nodeType === 'END'
            ? '#EEEEEE'
            : item.status === 'READED'
            ? '#F4F4F4'
            : '#EEEEEE',
      }"
    >
      <div
        class="icon"
        :style="{
          borderColor: setBorderColor(item.nodeType, item.status),
        }"
      ></div>
      <div class="title">
        <div
          style="
            font-size: 12px;
            width: fit-content;
            padding: 2px 4px;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid rgba(252, 165, 34, 0.6);
            display: flex;
            align-items: center;
          "
          :style="{
            color: setBorderColor(item.nodeType, item.status),
            borderColor: setBorderColor(item.nodeType, item.status),
          }"
        >
          <span
            style="
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background: #2e98fe;
              margin-right: 4px;
            "
            :style="{
              backgroundColor: setBorderColor(item.nodeType, item.status),
            }"
          ></span>
          {{ flowTilteText(item.nodeType, item.status) }}
        </div>
        <span
          style="
            font-size: 16px;
            font-family: MiSans-Medium, MiSans;
            font-weight: 600;
            color: #3d3d3d;
            line-height: 20px;
            margin-left: 8px;
          "
        >
          {{ item.nodeName }}
        </span>

        <div
          style="margin-left: auto; cursor: pointer"
          v-if="!['ROOT', 'END'].includes(item.nodeType)"
        >
          <i
            class="el-icon-arrow-up"
            v-if="item.isShowComment"
            @click="$set(item, 'isShowComment', false)"
          ></i>
          <i
            class="el-icon-arrow-down"
            v-else
            @click="$set(item, 'isShowComment', true)"
          ></i>
        </div>
      </div>
      <div
        v-for="info in item.taskList.filter((f) => f.nodeCode !== 'END')"
        :key="info.id"
      >
        <div class="info">
          <img
            class="avatar"
            :src="info.executorAvatar"
            alt=""
            v-if="info.executorAvatar"
          />
          <span class="avatarText" v-else>{{
            info.executorName ? info.executorName.slice(0, 2) : ""
          }}</span>
          <span style="margin: 0 8px; font-size: 14px; color: #888888">{{
            info.executorName
          }}</span>
          <span
            class="status"
            :style="{
              color: setBorderColor(info.nodeType, info.status),
              borderColor: setBorderColor(info.nodeType, info.status),
            }"
            >{{ setFlowText(info.nodeType, info.status) }}</span
          >
          <span class="time">{{ info.ended }}</span>
        </div>
        <div class="comment" v-if="!item.isShowComment && info.comment">
          {{ info.comment }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "flowLog",
  props: {
    flowLogList: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    console.log(this.flowLogList);
  },
  data() {
    return {
      urgeText: `尊敬的领导，您有一条【${this.$store.state.formData.pageNameList[0].formName}】的任务需要您尽快处理`,
      urge: false,
    };
  },
  methods: {
    handleUrge() {
      this.$emit("handleUrge", this.urgeText);
      this.urge = false;
    },
    setBorderColor(nodeType, status) {
      if (nodeType === "END" && status === "AGREE") return "#3D3D3D";
      else if (nodeType === "APPROVAL" && status === "AGREE") return "#00A47D";
      else if (status === "AGREE") return "#2E98FE";
      else if (status === "CANCEL") return "#D23100";
      else if (status === "DELIVER") return "#FCA522";
      else if (status === "NO_EXECUTE") return "#FCA522";
      else if (status === "REFUSE") return "#D23100";
    },
    flowTilteText(nodeType, status) {
      if (nodeType === "ROOT") return "提交";
      else if (nodeType === "APPROVAL" && status === "AGREE") return "已审批";
      else if (nodeType === "APPROVAL" && status === "NO_EXECUTE")
        return "进行中";
      else if (nodeType === "CC" && status === "AGREE") return "抄送";
      else if (nodeType === "APPROVAL" && status === "REFUSE") return "已驳回";
      else if (nodeType === "CC" && status === "CANCEL") return "已取消";
      else if (nodeType === "END") return "已结束";
    },
    setFlowText(nodeType, status) {
      if (nodeType === "ROOT") return "提交";
      else if (nodeType === "APPROVAL" && status === "AGREE") return "同意";
      else if (nodeType === "APPROVAL" && status === "NO_EXECUTE")
        return "进行中";
      else if (nodeType === "APPROVAL" && status === "DELIVER") return "转交";
      else if (nodeType === "CC" && status === "AGREE") return "抄送";
      else if (nodeType === "APPROVAL" && status === "REFUSE") return "不同意";
      else if (nodeType === "CC" && status === "CANCEL") return "已取消";
      else if (nodeType === "END") return "已结束";
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .el-step__icon-inner[class*="el-icon"]:not(.is-status) {
  font-size: 18px;
}

/deep/ .el-step.is-vertical .el-step__line {
  width: 1px;
}

.flowLogNode {
  padding: 12px;
  width: 294px;
  border-radius: 5px;
  color: #000;
  margin-bottom: 20px;
  position: relative;
  margin-left: 30px;
  .icon {
    position: absolute;
    left: -26px;
    top: 0;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    border: 2px solid #fca522;
  }
  &::after {
    position: absolute;
    height: 100%;
    content: "";
    left: -22px;
    top: 13px;
    border: 1px solid rgba(204, 204, 204, 0.5);
  }
  &:last-child {
    &::after {
      display: none;
    }
  }
  .title {
    display: flex;
    align-items: center;
  }
  .info {
    padding-top: 5px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    .avatar {
      vertical-align: middle;
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }
    .avatarText {
      display: inline-block;
      width: 24px;
      height: 24px;
      font-size: 12px;
      line-height: 24px;
      border-radius: 50%;
      text-align: center;
      background: #1890ff;
      color: white;
    }
    .status {
      border: 1px solid rgb(23, 215, 158);
      padding: 1px 5px;
      font-size: 12px;
      color: rgb(23, 215, 158);
      border-radius: 4px 4px 4px 4px;
    }
    .time {
      font-size: 12px;
      // text-align: right;
      color: rgba(61, 61, 61, 0.6);
      margin-left: auto;
    }
  }
  .comment {
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    padding: 5px;
    font-size: 14px;
    margin-top: 8px;
    color: #3d3d3d;
  }
}
/deep/ .el-icon-circle-close:before {
  color: red;
}
</style>
import requestAdmin from '@/utils/requestAdmin.js'

// 新增分组路由
export function updateGroup(data) {
  return requestAdmin({
    url: '/form-group',
    method: 'post',
    data
  })
}

// 查询所有分组
export function getAllFormGroups(moduleId = null) {
  return requestAdmin({
    url: `/form-group/list?moduleId=${moduleId}`,
    method: 'get',
  })
}

// 查询表单组
export function getFormGroups(moduleId) {
  return requestAdmin({
    url: `/form-group/list/detail?moduleId=${moduleId}`,
    method: 'get',
  })
}

// 删除表单组
export function deleteGroup(id) {
  return requestAdmin({
    url: `/form/${id}`,
    method: 'delete',
  })
}

// 新建分组&更新表单
export function createForm(param, method) {
  return requestAdmin({
    url: '/form',
    method,
    data: param
  })
}

// 查询表单详情
export function getFormDetail(id) {
  return requestAdmin({
    url: `/form/${id}`,
    method: 'get',
  })
}

// 查询表单字段项
export function getFormItem(formId) {
  return requestAdmin({
    url: `/form-item`,
    method: 'POST',
    data: {
      formId: formId,
      node: "add"
    }
  })
}

// 获取函数
export function getFnMap() {
  return requestAdmin({
    url: `/function/map`,
    method: 'get',
  })
}


// 校验函数
export function fnValidate(data) {
  return requestAdmin({
    url: `/form-rule/validate`,
    method: 'post',
    data
  })
}

// 新增或修改业务规则
export function updateRule(data) {
  return requestAdmin({
    url: `form-rule`,
    method: 'post',
    data
  })
}


// 获取业务规则列表
export function formRuleList(id) {
  return requestAdmin({
    url: `form-rule/list`,
    method: 'get',
    params: {
      formId: id
    }
  })
}


// 删除业务规则列表
export function delFormRule(id) {
  return requestAdmin({
    url: `form-rule?ruleId=${id}`,
    method: 'delete',
  })
}


// 复制表单详情
export function copyForm(id) {
  return requestAdmin({
    url: `/form/copy?formId=${id}`,
    method: 'post',
  })
}

// 重命名分组名
export function renameForm(data) {
  return requestAdmin({
    url: `/form-group`,
    method: 'put',
    data
  })
}

// 删除分组
export function delForm(groupId) {
  return requestAdmin({
    url: `/form-group/${groupId}`,
    method: 'DELETE',
  })
}

// 发送回复
export function sendComment(data) {
  return requestAdmin({
    url: `/data-cmt`,
    method: 'post',
    data
  })
}

// 获取回复列表
export function getCommentList(data) {
  return requestAdmin({
    url: `/data-cmt/page`,
    method: 'post',
    data
  })
}

// 删除回复信息
export function delCommentVal(id) {
  return requestAdmin({
    url: `/data-cmt/${id}`,
    method: 'DELETE',

  })
}


// 获取分页默认查询条件 
export function getLoadView(data) {
  return requestAdmin({
    url: `/form-data/loadView`,
    method: 'post',
    data
  })
}


// 手写签名验证 
export function invokeOcr(img) {
  return requestAdmin({
    url: `/openApi/invokeOcr`,
    method: 'post',
    data: {
      img
    }

  })
}

// 添加报错列表
export function errorMsg(message) {
  return requestAdmin({
    url: `/error_msg/add`,
    method: 'post',
    data: {
      message
    }
  })
}

// 获取报错列表
export function getErrorMsg(data) {
  return requestAdmin({
    url: `/error_msg/page`,
    method: 'post',
    data
  })
}
import request from '@/utils/request.js'
import requestFlow from '@/utils/requestFlow.js'

export function getLogin() {
    return requestFlow({
        url: '/user/get',
        method: 'get',
    })
}

export function getCode(phone) {
    return request({
        url: '/user/getLoginCode',
        method: 'get',
        params: {
            phone
        }
    })
}

export function loginByCode(phone, password) {
    return requestFlow({
        url: '/user/loginByCode',
        method: 'get',
        params: {
            phone,
            password
        }
    })
}

<template>
  <div style="width: 100%; min-width: 410px">
    <el-dialog
      append-to-body
      class="sign-dialog"
      title="请按照文字示意正确书写"
      :visible.sync="visible"
      width="440px"
      :close-on-click-modal="false"
    >
      <span
        @click="handleReset"
        style="
          position: absolute;
          left: 30px;
          top: 188px;
          cursor: pointer;
          color: #007aff;
          font-size: 16px;
          z-index: 3;
        "
        >输入预览：<span style="color: #222222">{{
          userinfo.name.slice(0, nowStr)
        }}</span></span
      >
      <span
        @click="handleReset"
        style="
          position: absolute;
          right: 30px;
          top: 188px;
          cursor: pointer;
          color: #007aff;
          font-size: 16px;
          z-index: 3;
        "
        >清空</span
      >
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 40px;
          color: #b4b4b4;
          margin-bottom: 20px;
          position: relative;
        "
      >
        <div
          v-for="(name, i) in userinfo.name"
          :key="i"
          style="padding: 10px 20px; margin-right: 20px"
          :class="{
            activeBox: i < nowStr,
            prepareBox: i === nowStr,
            defaultBox: i > nowStr,
          }"
        >
          {{ name }}
        </div>

        <div
          style="
            position: absolute;
            width: 400px;
            height: 400px;
            left: 0;
            bottom: -416px;
            font-weight: 500;
            font-size: 280px;
            text-align: center;
            color: #999da8;
            z-index: 1;
          "
        >
          {{ userinfo.name[nowStr] }}
        </div>
      </div>
      <div class="box" ref="printAre" v-loading="loading">
        <vue-esign
          ref="esign"
          :width="400"
          :height="400"
          :isCrop="isCrop"
          :lineWidth="lineWidth"
          :lineColor="lineColor"
          :bgColor.sync="bgColor"
          style="
            z-index: 2;
            position: absolute;
            width: 400px;
            height: 400px;
            top: 0;
          "
        />
      </div>
      <div
        style="
          text-align: center;
          color: #ff1717;
          font-size: 20px;
          margin-top: 10px;
        "
        v-if="isError"
      >
        请按示意字体规范书写！
      </div>

      <el-dialog
        :width="`${nowStr * 200 + 240}px`"
        title="请确认您的签名"
        :visible.sync="innerVisible"
        append-to-body
        :before-close="closeInnerVisible"
      >
        <div style="position: relative">
          <div style="z-index: 2; position: relative">
            <img
              v-for="(img, i) in succeedImgs"
              :key="i"
              :src="img"
              alt=""
              style="width: 160px; height: 160; padding: 20px"
            />
          </div>
          <div
            class="make"
            :style="{
              width: `${succeedImgs.length * 200}px`,
              transform: `translateX(-${(succeedImgs.length * 200) / 2}px)`,
            }"
          >
            <div class="list">
              <div
                class="item"
                v-for="i in 100"
                :key="i"
                style="font-size: 18px"
              >
                <span>仅限 {{ viewPage }}使用</span>
              </div>
            </div>
          </div>

          <div
            style="
              position: relative;
              bottom: 0;
              left: 50%;
              padding-left: 10px;
              color: #222222;
              text-align: left;
              z-index: 2;
              font-size: 16px;
            "
            :style="{
              width: `${succeedImgs.length * 200}px`,
              transform: `translateX(-${(succeedImgs.length * 200) / 2}px)`,
            }"
          >
            <div style="line-height: 20px">{{ userinfo.name }}</div>
            <div style="line-height: 20px">{{ setTime() }}</div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <!-- <el-button type="primary" @click="handleGenerate">生成签名</el-button> -->
          <el-button @click="closeInnerVisible">重新书写</el-button>
          <el-button type="primary" @click="submitUse">确认使用</el-button>
        </span>
      </el-dialog>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button type="primary" @click="handleGenerate">生成签名</el-button> -->
        <el-button @click="lastStep" v-if="nowStr !== 0">上一步</el-button>
        <el-button type="primary" @click="handleGenerate">下一步</el-button>
      </span>
    </el-dialog>

    <div
      style="
        font-size: 14px;
        color: rgb(201, 204, 216);
        border-radius: 4px;
        text-align: center;
        background: #f8fafc;
      "
      v-if="type === 'see'"
    >
      <div style="position: relative" v-if="visibleImgs.url">
        <div style="position: relative; z-index: 2">
          <img
            v-for="(img, i) in visibleImgs.url.split('|')"
            :key="i"
            :src="img"
            alt=""
            style="width: 90px; height: 90px; padding: 20px"
          />
        </div>
        <div
          class="make"
          :style="{
            width: `${visibleImgs.url.split('|').length * 120}px`,
            transform: `translateX(-${
              (visibleImgs.url.split('|').length * 120) / 2
            }px)`,
          }"
        >
          <div class="list">
            <div class="item" v-for="i in 100" :key="i" style="font-size: 18px">
              <span>仅限 {{ viewPage }}使用</span>
            </div>
          </div>
        </div>
        <div
          style="
            position: relative;
            bottom: 0;
            left: 50%;
            padding-left: 10px;
            color: #222222;
            text-align: left;
            z-index: 2;
            font-size: 16px;
          "
          :style="{
            width: `${visibleImgs.url.split('|').length * 120}px`,
            transform: `translateX(-${
              (visibleImgs.url.split('|').length * 120) / 2
            }px)`,
          }"
        >
          <div style="line-height: 20px">{{ visibleImgs.createName }}</div>
          <div style="line-height: 20px">{{ visibleImgs.createTime }}</div>
        </div>
      </div>
    </div>
    <div
      style="
        min-height: 88px;
        font-size: 14px;
        color: rgb(201, 204, 216);
        border-radius: 4px;
        border: 1px dashed rgb(212, 215, 224);
        text-align: center;
        background: rgb(255, 255, 255);
        position: relative;
      "
      v-else
    >
      <i
        class="el-icon-delete"
        style="
          position: absolute;
          right: 10px;
          top: 10px;
          cursor: pointer;
          z-index: 3;
        "
        @click="$set(formData, item.code, '')"
        v-if="visibleImgs.url"
      ></i>
      <span
        style="cursor: pointer; line-height: 88px"
        @click="init"
        v-if="!visibleImgs.url"
      >
        <i class="el-icon-edit"></i> 点击添加手写签名
      </span>
      <div v-else>
        <div style="position: relative; z-index: 2">
          <img
            v-for="(img, i) in visibleImgs.url.split('|')"
            :key="i"
            :src="img"
            alt=""
            style="width: 90px; height: 90px; padding: 20px"
          />
        </div>
        <div
          class="make"
          :style="{
            width: `${visibleImgs.url.split('|').length * 120}px`,
            transform: `translateX(-${
              (visibleImgs.url.split('|').length * 120) / 2
            }px)`,
          }"
        >
          <div class="list">
            <div class="item" v-for="i in 100" :key="i" style="font-size: 18px">
              <span>仅限 {{ viewPage }}使用</span>
            </div>
          </div>
        </div>
      </div>
      <div
        style="
          position: relative;
          bottom: 0;
          left: 50%;
          padding-left: 10px;
          color: #222222;
          text-align: left;
          z-index: 2;
          font-size: 16px;
        "
        v-if="visibleImgs.url"
        :style="{
          width: `${visibleImgs.url.split('|').length * 120}px`,
          transform: `translateX(-${
            (visibleImgs.url.split('|').length * 120) / 2
          }px)`,
        }"
      >
        <div style="line-height: 20px">{{ visibleImgs.createName }}</div>
        <div style="line-height: 20px">{{ visibleImgs.createTime }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Minxins from "../Minxins";
// 引入电子签名
import vueEsign from "vue-esign";
import { invokeOcr } from "@/api/design";
import html2canvas from "html2canvas";
export default {
  name: "Signature",
  mixins: [Minxins],
  components: { vueEsign },
  data() {
    return {
      visible: false,
      innerVisible: false,
      lineWidth: 3,
      lineColor: "#000000",
      bgColor: "#FFFFFF",
      resultImg: "",
      isCrop: true,
      isError: false,
      nowStr: 0,
      succeedImgs: [],
      loading: false,
      createTime: "",
    };
  },

  computed: {
    viewPage() {
      return this.$store.getters.viewPage.formName;
    },
    userinfo() {
      return this.$store.getters.userInfo;
    },
    visibleImgs() {
      return (
        (this.formData[this.item.code] &&
          JSON.parse(this.formData[this.item.code])) ||
        {}
      );
    },
  },
  methods: {
    lastStep() {
      if (this.nowStr === 0) return;
      this.nowStr -= 1;
      this.succeedImgs.pop();
      this.handleReset();
    },
    closeInnerVisible() {
      this.nowStr = 0;
      this.succeedImgs = [];
      this.isError = false;
      this.innerVisible = false;
    },
    setTime() {
      let now = new Date();
      let year = now.getFullYear();
      let month = now.getMonth() + 1;
      let day = now.getDate();
      let hours = now.getHours();
      let minutes = now.getMinutes();
      let seconds = now.getSeconds();
      const format = (v) => (v < 10 ? `0${v}` : `${v}`);
      return `${year}-${format(month)}-${format(day)} ${format(hours)}:${format(
        minutes
      )}:${format(seconds)}`;
    },
    submitUse() {
      let obj = {
        url: this.succeedImgs.join("|"),
        createTime: this.setTime(),
        createName: this.userinfo.name,
      };
      console.log(obj);
      this.$set(this.formData, this.item.code, JSON.stringify(obj));
      this.closeInnerVisible();
      this.visible = false;
    },
    // 初始化方法
    init() {
      this.visible = true;
      this.$nextTick(() => {
        this.$refs.esign.reset();
        this.nowStr = 0;
        this.succeedImgs = [];
      });
    },
    // 清空画板
    handleReset() {
      this.$refs.esign.reset();
    },

    //  下一步
    async handleGenerate() {
      this.$refs.esign
        .generate()
        .then((base64) => {
          html2canvas(this.$refs.printAre, {
            allowTaint: false,
            useCORS: true,
            height: this.$refs.printAre.outerHeight,
            width: this.$refs.printAre.outerWidth,
            windowWidth: document.body.scrollWidth,
            windowHeight: document.body.scrollHeight,
          }).then(async (img) => {
            console.log(img.toDataURL("image/png"));
            this.loading = true;
            let res = await invokeOcr(img.toDataURL("image/png"));
            this.loading = false;
            let words_result = JSON.parse(res.data).words_result;
            if (
              words_result &&
              words_result.length !== 0 &&
              words_result.find(
                (item) => item.words === this.userinfo.name[this.nowStr]
              )
            ) {
              this.handleReset();
              this.succeedImgs.push(base64);
              if (this.nowStr + 1 === this.userinfo.name.length) {
                this.innerVisible = true;
                return;
              }
              this.nowStr += 1;
              this.isError = false;
            } else {
              this.isError = true;
              this.handleReset();
            }
          });
        })
        .catch((err) => {
          this.$message.error("请先签字再点击下一步"); // 画布没有签字时会执行这里 'Not Signned'
        });
    },
  },
};
</script>

<style scoped lang="less">
.sign-dialog {
  .box {
    margin: 0 auto;
    background: #f6f6f6;
    border-radius: 3px;
    overflow: hidden;
    position: relative;
    height: 400px;
  }
}
.make {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 50%;
  z-index: 1;
  background: transparent;
  pointer-events: none;
  overflow: hidden;

  .list {
    width: 212%;
    height: 400%;
    position: absolute;
    top: -150%;
    left: -50%;
    transform: rotate(-36deg);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    pointer-events: none;
    .item {
      font-size: 28px;
      color: #ececec;
      font-weight: bold;
      padding: 10px;
      pointer-events: none;
    }
  }
}
.activeBox {
  border: 1px solid #007aff;
  color: #222222;
}
.prepareBox {
  border: 1px solid #000000;
  color: #222222;
}

.defaultBox {
  border: 1px solid #b4b4b4;
  color: #999ca3;
}
</style>

<template>
  <div>
    <div v-if="!item.type" class="disabledCss">
      {{ formData[item.code] }}
    </div>
    <el-autocomplete
      v-else
      v-get-selected-value
      size="medium"
      v-model="formData[item.code]"
      :fetch-suggestions="remoteMethod"
      placeholder="请输入内容"
      :trigger-on-focus="false"
      @select="queryList"
      @change="queryList"
      style="width: 100%"
      clearable
    ></el-autocomplete>
  </div>
</template>
<script>
import Minxins from "../Minxins";
import { getCandidateList } from "@/api/process";
export default {
  name: "SerialNumber",
  mixins: [Minxins],
  data() {
    return {};
  },

  methods: {
    async remoteMethod(val, cb) {
      let res = await getCandidateList(this.item.formId, "serial_number", val);
      let arr =
        res.data &&
        res.data.map((item) => {
          return { value: item };
        });
      cb(arr);
    },
  },
};
</script>

<style scoped lang="less">
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","min-width":"410px"}},[_c('el-dialog',{staticClass:"sign-dialog",attrs:{"append-to-body":"","title":"请按照文字示意正确书写","visible":_vm.visible,"width":"440px","close-on-click-modal":false},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('span',{staticStyle:{"position":"absolute","left":"30px","top":"188px","cursor":"pointer","color":"#007aff","font-size":"16px","z-index":"3"},on:{"click":_vm.handleReset}},[_vm._v("输入预览："),_c('span',{staticStyle:{"color":"#222222"}},[_vm._v(_vm._s(_vm.userinfo.name.slice(0, _vm.nowStr)))])]),_c('span',{staticStyle:{"position":"absolute","right":"30px","top":"188px","cursor":"pointer","color":"#007aff","font-size":"16px","z-index":"3"},on:{"click":_vm.handleReset}},[_vm._v("清空")]),_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center","font-size":"40px","color":"#b4b4b4","margin-bottom":"20px","position":"relative"}},[_vm._l((_vm.userinfo.name),function(name,i){return _c('div',{key:i,class:{
          activeBox: i < _vm.nowStr,
          prepareBox: i === _vm.nowStr,
          defaultBox: i > _vm.nowStr,
        },staticStyle:{"padding":"10px 20px","margin-right":"20px"}},[_vm._v(" "+_vm._s(name)+" ")])}),_c('div',{staticStyle:{"position":"absolute","width":"400px","height":"400px","left":"0","bottom":"-416px","font-weight":"500","font-size":"280px","text-align":"center","color":"#999da8","z-index":"1"}},[_vm._v(" "+_vm._s(_vm.userinfo.name[_vm.nowStr])+" ")])],2),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"printAre",staticClass:"box"},[_c('vue-esign',{ref:"esign",staticStyle:{"z-index":"2","position":"absolute","width":"400px","height":"400px","top":"0"},attrs:{"width":400,"height":400,"isCrop":_vm.isCrop,"lineWidth":_vm.lineWidth,"lineColor":_vm.lineColor,"bgColor":_vm.bgColor},on:{"update:bgColor":function($event){_vm.bgColor=$event},"update:bg-color":function($event){_vm.bgColor=$event}}})],1),(_vm.isError)?_c('div',{staticStyle:{"text-align":"center","color":"#ff1717","font-size":"20px","margin-top":"10px"}},[_vm._v(" 请按示意字体规范书写！ ")]):_vm._e(),_c('el-dialog',{attrs:{"width":`${_vm.nowStr * 200 + 240}px`,"title":"请确认您的签名","visible":_vm.innerVisible,"append-to-body":"","before-close":_vm.closeInnerVisible},on:{"update:visible":function($event){_vm.innerVisible=$event}}},[_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticStyle:{"z-index":"2","position":"relative"}},_vm._l((_vm.succeedImgs),function(img,i){return _c('img',{key:i,staticStyle:{"width":"160px","height":"160","padding":"20px"},attrs:{"src":img,"alt":""}})}),0),_c('div',{staticClass:"make",style:({
            width: `${_vm.succeedImgs.length * 200}px`,
            transform: `translateX(-${(_vm.succeedImgs.length * 200) / 2}px)`,
          })},[_c('div',{staticClass:"list"},_vm._l((100),function(i){return _c('div',{key:i,staticClass:"item",staticStyle:{"font-size":"18px"}},[_c('span',[_vm._v("仅限 "+_vm._s(_vm.viewPage)+"使用")])])}),0)]),_c('div',{staticStyle:{"position":"relative","bottom":"0","left":"50%","padding-left":"10px","color":"#222222","text-align":"left","z-index":"2","font-size":"16px"},style:({
            width: `${_vm.succeedImgs.length * 200}px`,
            transform: `translateX(-${(_vm.succeedImgs.length * 200) / 2}px)`,
          })},[_c('div',{staticStyle:{"line-height":"20px"}},[_vm._v(_vm._s(_vm.userinfo.name))]),_c('div',{staticStyle:{"line-height":"20px"}},[_vm._v(_vm._s(_vm.setTime()))])])]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.closeInnerVisible}},[_vm._v("重新书写")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submitUse}},[_vm._v("确认使用")])],1)]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[(_vm.nowStr !== 0)?_c('el-button',{on:{"click":_vm.lastStep}},[_vm._v("上一步")]):_vm._e(),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleGenerate}},[_vm._v("下一步")])],1)],1),(_vm.type === 'see')?_c('div',{staticStyle:{"font-size":"14px","color":"rgb(201, 204, 216)","border-radius":"4px","text-align":"center","background":"#f8fafc"}},[(_vm.visibleImgs.url)?_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticStyle:{"position":"relative","z-index":"2"}},_vm._l((_vm.visibleImgs.url.split('|')),function(img,i){return _c('img',{key:i,staticStyle:{"width":"90px","height":"90px","padding":"20px"},attrs:{"src":img,"alt":""}})}),0),_c('div',{staticClass:"make",style:({
          width: `${_vm.visibleImgs.url.split('|').length * 120}px`,
          transform: `translateX(-${
            (_vm.visibleImgs.url.split('|').length * 120) / 2
          }px)`,
        })},[_c('div',{staticClass:"list"},_vm._l((100),function(i){return _c('div',{key:i,staticClass:"item",staticStyle:{"font-size":"18px"}},[_c('span',[_vm._v("仅限 "+_vm._s(_vm.viewPage)+"使用")])])}),0)]),_c('div',{staticStyle:{"position":"relative","bottom":"0","left":"50%","padding-left":"10px","color":"#222222","text-align":"left","z-index":"2","font-size":"16px"},style:({
          width: `${_vm.visibleImgs.url.split('|').length * 120}px`,
          transform: `translateX(-${
            (_vm.visibleImgs.url.split('|').length * 120) / 2
          }px)`,
        })},[_c('div',{staticStyle:{"line-height":"20px"}},[_vm._v(_vm._s(_vm.visibleImgs.createName))]),_c('div',{staticStyle:{"line-height":"20px"}},[_vm._v(_vm._s(_vm.visibleImgs.createTime))])])]):_vm._e()]):_c('div',{staticStyle:{"min-height":"88px","font-size":"14px","color":"rgb(201, 204, 216)","border-radius":"4px","border":"1px dashed rgb(212, 215, 224)","text-align":"center","background":"rgb(255, 255, 255)","position":"relative"}},[(_vm.visibleImgs.url)?_c('i',{staticClass:"el-icon-delete",staticStyle:{"position":"absolute","right":"10px","top":"10px","cursor":"pointer","z-index":"3"},on:{"click":function($event){return _vm.$set(_vm.formData, _vm.item.code, '')}}}):_vm._e(),(!_vm.visibleImgs.url)?_c('span',{staticStyle:{"cursor":"pointer","line-height":"88px"},on:{"click":_vm.init}},[_c('i',{staticClass:"el-icon-edit"}),_vm._v(" 点击添加手写签名 ")]):_c('div',[_c('div',{staticStyle:{"position":"relative","z-index":"2"}},_vm._l((_vm.visibleImgs.url.split('|')),function(img,i){return _c('img',{key:i,staticStyle:{"width":"90px","height":"90px","padding":"20px"},attrs:{"src":img,"alt":""}})}),0),_c('div',{staticClass:"make",style:({
          width: `${_vm.visibleImgs.url.split('|').length * 120}px`,
          transform: `translateX(-${
            (_vm.visibleImgs.url.split('|').length * 120) / 2
          }px)`,
        })},[_c('div',{staticClass:"list"},_vm._l((100),function(i){return _c('div',{key:i,staticClass:"item",staticStyle:{"font-size":"18px"}},[_c('span',[_vm._v("仅限 "+_vm._s(_vm.viewPage)+"使用")])])}),0)])]),(_vm.visibleImgs.url)?_c('div',{staticStyle:{"position":"relative","bottom":"0","left":"50%","padding-left":"10px","color":"#222222","text-align":"left","z-index":"2","font-size":"16px"},style:({
        width: `${_vm.visibleImgs.url.split('|').length * 120}px`,
        transform: `translateX(-${
          (_vm.visibleImgs.url.split('|').length * 120) / 2
        }px)`,
      })},[_c('div',{staticStyle:{"line-height":"20px"}},[_vm._v(_vm._s(_vm.visibleImgs.createName))]),_c('div',{staticStyle:{"line-height":"20px"}},[_vm._v(_vm._s(_vm.visibleImgs.createTime))])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
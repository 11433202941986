import request from '@/utils/request.js'


// 查询组织架构树
export function getOrgTree(param) {
  return request({
    url: '/dept/tree',
    method: 'get',
    params: param
  })
}

// 查询系统角色
export function getUserInfo(id) {
  return request({
    url: `/user/getById?id=${id}`,
    method: 'get'
  })
}

// 搜索人员
export function getUserByName(param) {
  return request({
    url: '/dept/tree/user/search',
    method: 'get',
    params: param
  })
}


<template>
  <div class="page">
    <div
      v-if="dialogVisible"
      style="
        width: 100vw;
        height: 100vh;
        position: fixed;
        left: 0;
        top: 0;
        display: flex;
        background-color: rgba(33, 38, 51, 0.7);
        z-index: 100;
      "
      @mousewheel="handleMousewheel"
    >
      <div
        style="
          position: fixed;
          width: 68px;
          height: 68px;
          border-radius: 0 0 0 100%;
          right: 0;
          display: flex;
          background-color: #f7f8f9;
        "
      >
        <i
          @click="handleClose"
          class="el-icon-close"
          style="
            font-size: 30px;
            cursor: pointer;
            margin-top: 6px;
            margin-left: 30px;
          "
        ></i>
      </div>

      <img
        :src="imgUrl"
        alt=""
        style="margin: auto; animation: all 1s; max-width: 200px"
        :style="{ transform: `scale(${scale}) rotate(${rotate}deg)` }"
      />
      <div
        style="
          position: fixed;
          width: 340px;
          background-color: #fff;
          bottom: 50px;
          left: calc(50% - 170px);
          z-index: 101;
          display: flex;
          justify-content: space-around;
        "
        class="icons"
      >
        <span @click="rotate -= 90">
          <i class="el-icon-refresh-left"></i>
          左翻</span
        >
        <span @click="rotate += 90">
          <i class="el-icon-refresh-right"></i>
          右翻</span
        >
        <span @click="scale += 0.2">
          <i class="el-icon-zoom-in"></i>
          放大</span
        >
        <span @click="scale -= 0.2">
          <i class="el-icon-zoom-out"></i>
          缩小</span
        >
        <span
          @click="
            scale = 1;
            rotate = 0;
          "
        >
          <i class="el-icon-refresh"></i>
          还原</span
        >
      </div>
    </div>

    <div
      v-if="commentList && commentList.length > 0"
      class="content"
      ref="content"
      :style="{
        height: `calc(100% - ${
          isFocus && fileList && fileList.length > 0
            ? 200
            : isFocus
            ? 150
            : fileList.length > 0
            ? 100
            : 50
        }px)`,
      }"
    >
      <div
        v-for="(item, i) in commentList"
        :key="`${item.id}-${i}`"
        class="item"
      >
        <div class="info">
          <img
            :src="item.avatarUrl"
            alt=""
            style="width: 38px; border-radius: 50%; height: 38px"
          />
          <div class="name">
            <span style="font-size: 15px; color: #222222">{{
              item.createName
            }}</span>
            <span
              style="
                font-size: 12px;
                color: #777777;
                display: flex;
                align-items: center;
              "
              >{{ item.created }}
              <i
                class="el-icon-delete"
                style="font-size: 12px; margin-left: 8px; cursor: pointer"
                v-if="item.createId === user.id"
                @click="handleSendValDel(item)"
              ></i>
              <img
                src="https://ybr-test.oss-cn-shanghai.aliyuncs.com/public/20240301142402/message.png"
                style="
                  width: 14px;
                  height: 12px;
                  margin-left: 10px;
                  cursor: pointer;
                "
                v-if="!item.send"
                alt=""
                @click="handleOpenBox(item)"
            /></span>
          </div>
        </div>
        <div class="text">
          <span v-for="(text, i) in item.text.split(' ')" :key="i">
            <span v-if="text.includes('@')">
              <span v-for="(infoText, num) in text.split('@')" :key="num">
                <span v-if="num === 0">{{ infoText }}</span>
                <span v-else style="color: #1890ff"
                  >@
                  <span v-if="!infoText.includes('-')">{{ infoText }}</span>
                  <el-tooltip
                    class="item"
                    effect="light"
                    :content="infoText"
                    placement="top"
                    v-else
                  >
                    <span>{{ infoText.split("-")[0] }}</span>
                  </el-tooltip>
                </span>
              </span>
            </span>
            <span v-else>{{ text }}</span>
          </span>
        </div>
        <div class="files" v-if="item.files">
          <div
            v-for="(file, fileIndex) in item.files"
            :key="`${file.id}-${fileIndex}`"
            style="cursor: pointer; margin-top: 10px; margin-right: 10px"
            @click="handlePreview(file)"
          >
            <img
              :src="file.url"
              v-if="file.url"
              alt=""
              style="width: 70px; height: 70px; border-radius: 4px"
            />
            <i
              class="el-icon-folder-opened"
              v-else
              style="font-size: 70px; color: #aaaaaa"
            ></i>
          </div>
        </div>

        <div v-if="item.send">
          <div class="send">
            <textarea
              name="value"
              id="value"
              style="
                width: 100%;
                height: 75px;
                color: #304265;
                border: none;
                resize: none;
                outline: none;
              "
              :placeholder="`回复${item.createName}`"
              v-model="sendValue"
            ></textarea>
            <p
              style="
                text-align: right;
                color: #c9ccd8;
                font-size: 12px;
                margin: 0;
              "
            >
              {{ sendValue.length || 0 }}/2000
            </p>
          </div>
          <div
            style="
              font-size: 14px;
              text-align: right;
              margin-left: 50px;
              margin-top: 10px;
              width: 266px;
            "
          >
            <button
              style="
                border-radius: 4px;
                background-color: #fff;
                border: 1px solid #d9d9d9;
                margin-right: 10px;
              "
              @click="$set(item, 'send', false)"
            >
              取消
            </button>
            <button
              style="
                border-radius: 4px;
                color: #fff;
                background-color: #1890ff;
                border: 1px solid #1890ff;
              "
              @click="handleSendVal(item)"
            >
              确定
            </button>
          </div>
        </div>
        <div class="comments" v-if="item.comments">
          <div
            v-for="(comment, commentIndex) in item.comments"
            :key="`${comment.id}-${commentIndex}`"
            style="margin-bottom: 7px"
          >
            <span style="color: #1890ff; font-size: 15px"
              >{{ comment.createName }}：</span
            >
            <span
              style="
                color: #364a61;
                font-size: 15px;
                white-space: pre-wrap;
                word-break: break-all;
              "
              >{{ comment.text }}</span
            >
            <div style="color: #777777; font-size: 12px">
              {{ comment.created }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-empty description="暂无数据" v-else></el-empty>

    <div class="foot">
      <div class="user">
        <el-image
          :src="user.avatar"
          class="user-avatar"
          v-if="user.avatar"
        ></el-image>
        <span v-else class="user-avatar">{{
          user.name && user.name.split("-").length > 1
            ? user.name
                .split("-")[0]
                .slice(
                  user.name.split("-")[0].length - 2,
                  user.name.split("-")[0].length
                )
            : user.name
        }}</span>
      </div>
      <div
        style="
          flex: 1;
          position: relative;
          width: 300px;
          height: fit-content;
          border: 1px solid #aaaaaa;
          border-radius: 10px;
          flex: 1;
        "
        :style="{
          'border-radius': isFocus ? '10px' : '15px',
          padding: isFocus ? '5px 6px 33px;' : '5px 6px',
        }"
      >
        <div
          style="height: 25px; cursor: pointer"
          class="inputVal"
          placeholder="留下你的评论 可@成员"
          v-if="!isFocus"
          @click="handleFocus"
        ></div>
        <div
          v-else
          contenteditable="true"
          :style="{ height: '130px' }"
          class="inputVal"
          placeholder="留下你的评论 可@成员"
          tabindex="0"
          ref="input"
          @input="handleInput"
          @blur="handleBlur"
          @keydown="handleKeydown"
          @compositionstart="handleCompositionstart"
          @compositionend="handleCompositionend"
        ></div>
        <div
          v-if="userList && userList.length >= 1"
          class="userList"
          :style="{
            top: location.top + 'px',
            left: location.left + 'px',
          }"
        >
          <div
            v-for="item in userList"
            :key="item.id"
            style="cursor: pointer; padding: 2px 4px"
            @click="handleAtUser(item)"
            class="userItem"
          >
            {{ item.name }}
          </div>
        </div>
        <div
          class="fileList"
          v-if="fileList && fileList.length > 0"
          style="padding-bottom: 24px; display: flex; padding-top: 15px"
        >
          <div
            v-for="item in fileList"
            :key="item.id"
            class="fileItem"
            style="position: relative"
          >
            <span>
              <i
                class="el-icon-folder-opened"
                v-if="!item.url"
                style="font-size: 30px; color: #aaaaaa"
              ></i>
              <img
                v-else
                :src="item.url"
                alt=""
                style="width: 30px; height: 30px; margin-right: 5px"
              />
              <span
                style="
                  font-size: 10px;
                  position: absolute;
                  right: 0;
                  top: -5px;
                  z-index: 2;
                "
              >
                <img
                  src="https://ybr-test.oss-cn-shanghai.aliyuncs.com/public/20240301100428/删除.png"
                  alt=""
                  style="width: 10px; cursor: pointer"
                  @click="handleDelFile(item)"
                />
              </span>
            </span>
          </div>
        </div>
        <div class="submit">
          <el-upload
            ref="upload"
            :action="url"
            :file-list="fileList"
            :show-file-list="false"
            with-credentials
            :multiple="true"
            :limit="9"
            :before-upload="beforeUpload"
            :on-success="handleAvatarSuccess"
            :on-exceed="handleExceed"
            :headers="{
              Authorization: `Bearer ${$store.getters.token}`,
            }"
          >
            <el-tooltip
              class="item"
              content="上传附件/图片(最多支持9个，单个大小不超过100M)"
              placement="top"
            >
              <img
                src="https://ybr-test.oss-cn-shanghai.aliyuncs.com/public/20240229145006/upload.png"
                alt=""
              />
            </el-tooltip>
          </el-upload>
          <span
            @click="submit"
            :style="{
              backgroundColor: !colorFlag ? '#aaaaaa' : '#1890ff',
            }"
          >
            发送
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserByName } from "@/api/org";
import { sendComment, delCommentVal } from "@/api/design";
import { client } from "@/utils/oss";
export default {
  props: {
    commentList: {
      type: Array,
      default: () => [],
    },
  },
  name: "Comment",
  data() {
    return {
      user: this.$store.getters.userInfo || {},
      commentVal: "",
      fakeDom: null,
      isFocus: false,
      userList: [],
      fileList: [],
      url: "",
      isChinese: false,
      location: {
        left: 0,
        top: 0,
      },
      list: [],
      sendValue: "",
      dialogVisible: false,
      imgUrl: "",
      scale: 1,
      rotate: 0,
      colorFlag: false,
    };
  },
  created() {
    this.url = `${process.env.VUE_APP_BASE_URL}/cloud-admin/file/upload?formId=${this.$route.query.code}&type=1`;
  },
  methods: {
    handleMousewheel(e) {
      if (e.deltaY > 1) {
        this.scale -= 0.2;
      } else {
        this.scale += 0.2;
      }
    },
    handlePreview(file) {
      if (file.url) {
        this.imgUrl = file.url;
        this.dialogVisible = true;
      } else {
        const a = document.createElement("a");
        const url = client.signatureUrl(file.path);
        if (url) {
          a.href = url;
        }
        a.download = file.name;
        a.target = "_blank";
        a.click();
        a.remove();
      }
    },
    handleClose() {
      this.imgUrl = "";
      this.scale = 1;
      this.rotate = 0;
      this.dialogVisible = false;
    },
    handleSendValDel(item) {
      this.$confirm("确定删除这条评论吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await delCommentVal(item.id);
          console.log(res);
          if (res.code !== 200)
            return this.$message({
              type: "info",
              message: "服务器错误！",
            });
          let arr = this.commentList.filter((k) => k.id !== item.id);
          this.$emit("update:commentList", arr);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleOpenBox(item) {
      // this.$set(item, "send", true);
      this.commentList.forEach((k) => {
        if (item.id === k.id) this.$set(k, "send", true);
        else this.$set(k, "send", false);
      });
    },
    async handleSendVal(item) {
      if (!this.sendValue) return this.$message.info("请输入内容后再发送！");
      this.$set(item, "send", false);
      let res = await sendComment({
        dataId: this.$route.query.objId,
        formId: this.$route.query.code,
        text: this.sendValue,
        replyId: item.id,
      });
      if (res.code === 200) {
        this.sendValue = "";
        this.$set(item, "comments", [...(item.comments || []), res.data]);
      }
    },
    handleDelFile(item) {
      // console.log(item);
      this.fileList = this.fileList.filter((e) => e.id !== item.id);
    },
    async submit() {
      let dom = this.$refs.input;
      if ((!dom || !dom.innerHTML) && this.fileList.length < 1)
        return this.$message.info("请输入内容后再发送！");
      let str = "";
      // console.log(dom.childNodes);
      if (dom) {
        dom.childNodes.forEach((item) => {
          // console.log(item);
          if (item.nodeName === "#text") {
            // console.log(item.nodeValue);
            str += item.nodeValue;
          } else {
            str += item.innerHTML;
          }
        });
        dom.innerHTML = "";
        this.colorFlag = false;
      }
      let res = await sendComment({
        files: this.fileList,
        dataId: this.$route.query.objId,
        formId: this.$route.query.code,
        text: str,
      });
      if (res.code === 200) {
        this.$emit("update:commentList", [res.data, ...this.commentList]);
        this.fileList = [];
        this.$refs.content && this.$refs.content.scrollTo(0, 0);
        this.handleBlur();
      }
    },
    handleAvatarSuccess(res) {
      // console.log(res);
      if (res && res.code === 200) {
        this.fileList.push(res.data);
      }
      // this.$refs.upload.clearFiles();
    },
    handleExceed(files, fileList) {
      if (fileList.length > 8) this.$message("最多上传9个文件");
    },
    beforeUpload(file) {
      const flag = file.size / 1024 / 1024;
      if (flag > 100) {
        this.$message("上传的图片大小必须小于100兆");
        return false;
      }
      return true;
    },
    handleAtUser(item) {
      // console.log(item);
      this.userList = [];
      let newSpan = document.createElement("span");
      newSpan.innerText = `@${item.name} `;
      newSpan.setAttribute("contenteditable", "false");
      newSpan.style.color = "#107fff";
      let dom = this.$refs.input;
      // console.log(dom.childNodes.split("@"));
      if (dom.childNodes) {
        dom.childNodes.forEach((item) => {
          if (item.nodeName === "#text") {
            // console.log(item.nodeValue);
            item.nodeValue = item.nodeValue.slice(
              0,
              item.nodeValue.lastIndexOf("@")
            );
          }
        });
      }
      dom.appendChild(newSpan);
      // 创建一个范围对象
      const range = document.createRange();
      // 清除任何现有的选择
      const selection = window.getSelection();
      selection.removeAllRanges();
      // 设置范围的起始和结束位置到可编辑元素的末尾
      range.selectNodeContents(dom);
      range.collapse(false); // 设置为末尾位置
      // 将范围添加到选择中
      selection.addRange(range);
      // 将焦点设置到可编辑元素
      dom.focus();
    },

    async handleKeydown(e) {
      if (e.key === "@") {
        // console.log(e);
        this.search("");
      }
    },
    // 中文情况输入途中
    handleCompositionstart(e) {
      this.isChinese = true;
    },
    // 中文情况输入结束
    handleCompositionend(e) {
      this.isChinese = false;
      this.handleInput();
    },
    // 定位弹窗
    async search(name) {
      if (this.isChinese) return;
      let res = await getUserByName({ name });
      this.userList = res.data;
      //  下面操作为异步
      const sel = window.getSelection();
      const range = document.createRange();
      range.selectNode(sel.focusNode);
      range.setStart(sel.focusNode, sel.focusOffset);
      if (this.$refs["input"].innerHTML === "") {
        this.location.left = 0;
        this.location.top = -120;
      } else {
        this.location.left = range.getBoundingClientRect().x;
        this.location.top = range.getBoundingClientRect().y - 130;
      }
      // console.log(this.location);
    },
    // 实时输入内容获取@的人
    handleInput() {
      if (this.isChinese) return;
      let domArr = this.$refs.input.childNodes;
      this.colorFlag = !!this.$refs.input.innerHTML;
      domArr.forEach((item) => {
        if (item.nodeName === "#text" && item.nodeValue.indexOf("@") > -1) {
          let str = item.nodeValue.slice(
            item.nodeValue.lastIndexOf("@") + 1,
            item.nodeValue.length
          );
          this.search(str);
        } else {
          this.userList = [];
        }
      });
    },
    // 如果没有内容失焦变回原来小的输入框
    handleBlur() {
      let dom = this.$refs.input;
      if (dom && dom.innerHTML === "") {
        this.isFocus = false;
      }
    },
    // 表单聚焦
    handleFocus() {
      this.isFocus = true;
      setTimeout(() => {
        this.$refs.input.focus();
      }, 100);
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  position: relative;
  height: calc(100vh - 188px);
  overflow: hidden;
  .icons {
    border-radius: 10px;

    span {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
      cursor: pointer;
    }
  }
  .content {
    overflow: auto;
    .item {
      margin-bottom: 20px;
      .info {
        display: flex;
        .name {
          display: flex;
          flex-direction: column;
          margin-left: 12px;
          justify-content: space-between;
        }
      }
      .text {
        margin-left: 50px;
        margin-top: 12px;
        color: #364a61;
        width: 250px;
        white-space: pre-wrap;
        word-break: break-all;
      }
      .files {
        margin-left: 50px;
        margin-top: 2px;
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        width: 240px;
      }
      .send {
        width: 240px;
        margin-top: 10px;
        margin-left: 50px;
        border: 1px solid #d4d7e0;
        border-radius: 4px;
        padding: 5px 12px;
      }
      .comments {
        margin-left: 50px;
        background-color: #f4f4f4;
        padding: 12px;
        width: 240px;
        margin-top: 10px;
      }
    }
  }
  .foot {
    position: absolute;
    display: flex;
    width: 100%;
    bottom: 0;
    left: 0;
    justify-content: space-between;
    align-items: flex-end;
    background: #fff;
    .user {
      .user-avatar {
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        background: #1890ff;
        color: white;
        border-radius: 50%;
        display: block;
      }
      margin-right: 10px;
    }
    .inputVal {
      &:empty::before {
        content: attr(placeholder);
        color: #aaaaaa;
        font-size: 14px;
      }
      position: relative;
      overflow: auto;
    }
    .submit {
      position: absolute;
      bottom: 3px;
      right: 9px;
      display: flex;
      align-items: center;
      img {
        width: 27px;
        height: 27px;
        margin-right: 6px;
        cursor: pointer;
        vertical-align: middle;
      }
      span {
        font-size: 14px;
        color: #ffffff;
        padding: 5px 9px;
        border-radius: 20px;
        cursor: pointer;
      }
    }
    .userList {
      position: fixed;
      left: 0;
      width: 120px;
      height: 120px;
      border: 1px solid #aaaaaa;
      border-radius: 4px;
      overflow: auto;
      z-index: 2;
      background-color: #fff;
      box-shadow: 0 0 10px 0 rgba(101, 111, 122, 0.5);
      font-size: 14px;
      .userItem {
        &:hover {
          background: #5bb8ff;
          color: #fff;
        }
      }
      .fileItem {
        width: 30px;
        height: 30px;
      }
    }
  }
}
</style>
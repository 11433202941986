import Vue from "vue";
import axios from "axios";


// 第三方插件
import "element-ui/lib/theme-chalk/index.css";
import store from "@/store";
import { Message } from 'element-ui';

Vue.prototype.$axios = axios;
// 字体图标
const serviceAdmin = axios.create({
	baseURL: `${window.location.href.includes('http://test') ? 'http://test.yibeirui.com/gateway/' : process.env.VUE_APP_BASE_URL}/cloud-flow`,
	// baseURL: 'http://192.168.1.78:5555/cloud-flow',
	timeout: 50000
});

serviceAdmin.defaults.withCredentials = true; // 让ajax携带cookie
// 添加请求拦截器
serviceAdmin.interceptors.request.use(
	// 每次请求都自动携带Cookie
	config => {
		//config.headers.Cookie = document.cookie
		let token = store.getters.token
		if (token) {
			config.headers.Authorization = `Bearer ${token}`
			config.headers.client = `pc`
		}

		return config;
	},
	// eslint-disable-next-line handle-callback-err
	error => {
		return Promise.reject(error);
	}
);
// 添加响应拦截器
serviceAdmin.interceptors.response.use(
	rsp => {
		if (rsp.data.code === 500) {
			Message.error("服务器错误,请稍等");
		}
		if (rsp.data.msg === '验证失败：token不正确') {
			store.dispatch('user/logOut')
			Message.error("token不正确,请重新登陆");
		}
		return rsp.data;
	},
	// 拦截异常的响应
	err => {
		console.log("请求", err);
		//throw 'request error'
		return Promise.reject(err);
	}
);

export default serviceAdmin;

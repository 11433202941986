import requestAdmin from '@/utils/requestAdmin.js'

// 获取列表和搜索字段
export function getDrawerPageOption(id, node) {
    return requestAdmin({
        url: `/form-item`,
        method: 'post',
        data: {
            formId: id,
            node
        }
    })
}

export function pageListAdd(data, id, node) {
    return requestAdmin({
        url: `/form-data`,
        method: 'post',
        data: {
            data,
            formId: id,
            node
        }
    })
}

export function pageListDel(formId, ids) {
    return requestAdmin({
        url: `/form-data/`,
        method: 'DELETE',
        data: {
            formId, ids
        }
    })
}

export function getPageOneInfo(formId, id) {
    return requestAdmin({
        url: `/form-data/${formId}/${id}`,
        method: 'get',

    })
}

// 获取关联表单
export function getRelevanceList(formId,objId) {
    return requestAdmin({
        url: `/form/relevance/${formId}/${objId}`,
        method: 'get',
    })
}
<template>
  <div
    class="printBox"
    v-if="_showPrintPage"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(255, 255, 255, 0.9)"
  >
    <div class="printPage" ref="printAre">
      <h2 style="text-align: center">
        {{ $store.getters.viewPage.formName }}
      </h2>
      <el-row>
        <el-col
          v-for="(item, index) in field"
          :key="item.code + index"
          :span="item.parentId ? 12 : 24"
        >
          <div class="itemInfo" v-if="item.name !== 'TableList'">
            <div class="title">{{ item.title }}</div>
            <div class="code">
              {{ setCodeName(formOptionInfo[item.code], item) }}
            </div>
          </div>
          <div v-else class="tableBox">
            <div
              style="
                text-align: center;
                border-left: 1px solid #c0c4cc;
                border-right: 1px solid #c0c4cc;
                line-height: 26px;
                color: #909399;
                font-weight: 500;
              "
            >
              {{ item.title }}
            </div>
            <el-table
              :data="formOptionInfo[item.code]"
              border
              style="width: 100%"
              :cell-style="{ borderColor: '#c0c4cc' }"
              :header-cell-style="{ borderColor: '#c0c4cc' }"
            >
              <el-table-column
                v-for="column in item.items"
                :key="column.id"
                :label="column.title"
                :prop="column.code"
                min-width="20px"
              >
                <template #default="{ row }">{{
                  setCodeName(row[column.code], column)
                }}</template>
              </el-table-column>
            </el-table>
          </div>
        </el-col>
      </el-row>
      <div
        style="
          text-align: center;
          border-left: 1px solid #c0c4cc;
          border-right: 1px solid #c0c4cc;
          line-height: 26px;
          color: #909399;
          font-weight: 500;
        "
        v-if="logList && logList.length > 0"
      >
        流程日志
      </div>
      <el-table
        :data="logList"
        border
        style="width: 100%"
        v-if="logList && logList.length > 0"
        :cell-style="{ borderColor: '#c0c4cc' }"
        :header-cell-style="{ borderColor: '#c0c4cc' }"
      >
        <el-table-column label="审批人" prop="executorName" align="center">
        </el-table-column>
        <el-table-column label="审批节点" prop="nodeName" align="center">
        </el-table-column>
        <el-table-column
          label="审批结果"
          prop="status"
          align="center"
          :formatter="
            (row, column, cellValue) =>
              cellValue === 'AGREE'
                ? '同意'
                : cellValue === 'CANCEL'
                ? '撤销'
                : cellValue === 'DELIVER'
                ? '转交'
                : cellValue === 'NO_EXECUTE'
                ? '未执行'
                : cellValue === 'REFUSE'
                ? '拒绝'
                : ''
          "
        >
        </el-table-column>
        <el-table-column label="审批时间" prop="started" align="center">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import print from "print-js";
import html2canvas from "html2canvas";
export default {
  name: "PrintPage",
  props: {
    showPrintPage: {
      type: Boolean,
      default: false,
    },
    pageNameList: {
      type: Array,
      default: () => [],
    },
    formOptionInfo: {
      type: Object,
      default: () => {},
    },
    logList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    _showPrintPage: {
      get() {
        return this.showPrintPage;
      },
      set(val) {
        this.$emit("update:showPrintPage", val);
      },
    },
    field() {
      return this.$store.state.formData.field;
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  watch: {
    _showPrintPage(val) {
      if (val) {
        let focuser = setInterval(() => {
          window.dispatchEvent(new Event("focus"));
        }, 500);

        setTimeout(() => {
          // 因为是动态内容用原生的打印的事件是无法做分页 的
          // html2canvas组件做分页更简单
          html2canvas(this.$refs.printAre, {
            allowTaint: false,
            useCORS: true,
            height: this.$refs.printAre.outerHeight,
            width: this.$refs.printAre.outerWidth,
            windowWidth: document.body.scrollWidth,
            windowHeight: document.body.scrollHeight,
          }).then((res) => {
            if (res) this.loading = false;
            print({
              printable: res.toDataURL("image/png"), // 'printFrom', // 标签元素
              // header: ``,
              type: "image",
              style: `@media print { @page {size: auto; margin: 0; } body{margin:0 10px}}`, // 解决出现多页打印时第一页空白问题
              // 取消打印事件
              // 触发该事件需要点击取消或者打印后切换一下当前页面
              // 用window.dispatchEvent主动调用触发事件
              onPrintDialogClose: () => {
                this.$nextTick(() => {
                  clearInterval(focuser);
                  this._showPrintPage = false;
                  this.loading = true;
                });
              },
            });
          });
        }, 300);
      }
    },
  },
  methods: {
    setCodeName(info, item) {
      let res = Object.prototype.toString.call(info);
      if (
        ["[object String]", "[object Undefined]", "[object Number]"].includes(
          res
        )
      ) {
        if (item.name === "Signature") return "";
        else return info;
      } else if (res === "[object Array]") {
        let alisaArr = [
          "CreatedBy",
          "OwnerId",
          "UserPicker",
          "MultipleUserPicker",
        ];
        let nameArr = [
          "CreatedDept",
          "MultipleDeptPicker",
          "DeptPicker",
          "CreatedDept",
        ];
        if (alisaArr.includes(item.name))
          return info.reduce((pre, cur) => (pre += `${cur.alisa} `), "");
        if (nameArr.includes(item.name))
          return info.reduce((pre, cur) => (pre += `${cur.name} `), "");
        if (item.name === "MultipleRelevance")
          return info.reduce((pre, cur) => (pre += `${cur.Name} `), "");
        if (item.name === "MultipleSelect") return info.join(",");
      } else {
        if (item.name === "Relevance") return info.Name;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.el-table td,
.el-table th.is-leaf,
.el-table--border,
.el-table--group {
  border-color: #c0c4cc;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  background-color: #c0c4cc;
}

.printBox {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  background-color: #fff;
}
.printPage {
  width: 800px;
  margin: 0 auto;
  padding: 10px;
  overflow: auto;
}
.itemInfo {
  display: flex;
  border: 1px solid #c0c4cc;
  box-sizing: border-box;
  margin-right: -1px;
  margin-bottom: -1px;
  color: #909399;
  font-weight: 500;
  .title {
    width: 25%;
    border-right: 1px solid #c0c4cc;
    line-height: 26px;
    padding-left: 5px;
  }
  .code {
    padding-left: 5px;
    flex: 1;
    line-height: 26px;
  }
}
.tableBox {
  border-bottom: none;
}
</style>
const getters = {
    token: state => state.user.token,
    name: state => state.user.userInfo.name,
    userInfo: state => state.user.userInfo,
    formName: state => state.formData.tableData.formName,
    viewPage: state => state.formData.pageNameList[
        state.formData.pageNameList.length - 1
    ]
}
export default getters

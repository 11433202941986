<template>
  <div :class="{ ImageUpload: type === 'see' }">
    <el-upload
      :disabled="disabled"
      ref="upload"
      :action="url"
      :limit="5"
      :multiple="true"
      :on-success="handleAvatarSuccess"
      list-type="picture-card"
      :file-list="formData[item.code]"
      :before-upload="beforeUpload"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :class="{
        hideshow:
          (formData[item.code] && formData[item.code].length >= 5) ||
          type === 'see',
      }"
      :headers="{
        Authorization: `Bearer ${$store.getters.token}`,
      }"
    >
      <i class="el-icon-plus" />
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" :modal="false" title="图片预览">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import Minxins from "../Minxins";
export default {
  name: "ImageUpload",
  mixins: [Minxins],
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      url: "",
    };
  },
  created() {
    this.url = `${process.env.VUE_APP_BASE_URL}/cloud-admin/file/upload?formId=${this.$route.query.code}&type=1`;
  },
  methods: {
    handleAvatarSuccess(res) {
      if (res && res.code === 200) {
        if (!this.formData[this.item.code])
          this.$set(this.formData, this.item.code, []);
        this.formData[this.item.code].push(res.data);
      }
    },
    beforeUpload(file) {
      const types = ["image/jpeg", "image/gif", "image/bmp", "image/png"];
      if (!types.includes(file.type)) {
        this.$message("上传的图片格式, 必须是jpg, gif, bmp, png的格式!");
        return false;
      }
      const flag = file.size / 1024 / 1024;
      if (flag > 5) {
        this.$message("上传的图片大小必须小于5兆");
        return false;
      }
      return true;
    },
    handleRemove(file, fileList) {
      this.formData[this.item.code] = [...fileList];
    },
    // 图片预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.design {
  i {
    padding: 10px;
    font-size: xx-large;
    background: white;
    border: 1px dashed #8c8c8c;
  }
}
/deep/ .el-upload--picture-card {
  width: 80px;
  height: 80px;
  line-height: 87px;
}
/deep/ .el-upload-list__item {
  width: 80px;
  height: 80px;
  .el-upload-list__item-actions {
    & > span + span {
      margin: 1px;
    }
  }
}

.hideshow /deep/ .el-upload--picture-card {
  display: none;
}

.ImageUpload {
  background-color: #f8fafc;
  border-radius: 4px;
  padding: 5px;
  height: 80px;
}

.upload {
  .el-icon-plus {
    padding: 30px;
    border: 1px dashed #8c8c8c;
  }
}
</style>

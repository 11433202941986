<template>
  <div :class="{ FileUpload: type === 'see' }">
    <el-upload
      ref="upload"
      :file-list="formData[item.code]"
      :action="url"
      :limit="5"
      :disabled="disabled"
      with-credentials
      :multiple="true"
      :on-success="handleAvatarSuccess"
      :on-remove="handleRemove"
      :on-preview="handlePreview"
      :class="{ hideshow: type === 'see' }"
      :headers="{
        Authorization: `Bearer ${$store.getters.token}`,
      }"
    >
      <el-button
        v-if="type !== 'see'"
        size="small"
        icon="el-icon-paperclip"
        round
        >选择文件</el-button
      >
    </el-upload>
  </div>
</template>

<script>
import Minxins from "../Minxins";
import { client } from "@/utils/oss";
export default {
  name: "FileUpload",
  mixins: [Minxins],
  data() {
    return {
      url: "",
    };
  },
  created() {
    this.url = `${process.env.VUE_APP_BASE_URL}/cloud-admin/file/upload?formId=${this.$route.query.code}&type=1`;
  },
  methods: {
    handleAvatarSuccess(res) {
      if (res && res.code === 200) {
        if (!this.formData[this.item.code])
          this.$set(this.formData, this.item.code, []);
        this.formData[this.item.code].push(res.data);
      }
    },
    async handlePreview(file) {
      const a = document.createElement("a");
      const url = client.signatureUrl(file.path);
      if (url) {
        a.href = url;
      }
      a.download = file.name;
      a.target = "_blank";
      a.click();
      a.remove();
    },
    handleRemove(file, fileList) {
      this.formData[this.item.code] = [...fileList];
    },
  },
};
</script>

<style lang="less" scoped>
.hideshow /deep/ .el-upload {
  display: none;
}

.upload {
  .el-icon-paperclip {
    border: 1px dashed #8c8c8c;
    padding: 10px 20px;
  }
}

.FileUpload {
  background-color: #f8fafc;
  border-radius: 4px;
  padding: 5px;
  height: 100%;
  min-height: 42px;
}
</style>

<template>
  <div style="color: #868686; width: 100%">
    <el-icon class="el-icon-warning-outline"></el-icon>
    <span> {{ item.options }}</span>
  </div>
</template>

<script>
import  Minxins  from "../Minxins";
export default {
  name: "Description",
  mixins: [Minxins],
};
</script>

<style scoped>
</style>

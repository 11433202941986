var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isVisibility)?_c('div',[_c('el-drawer',{attrs:{"title":"","visible":_vm.isVisibility,"direction":"rtl","before-close":_vm.handleClose,"size":"100%","modal":false,"destroy-on-close":""},on:{"update:visible":function($event){_vm.isVisibility=$event}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[(_vm.pageNameList.length === 1)?_c('div',{staticStyle:{"color":"#3d3d3d","margin-left":"10px"}},[_vm._v(" "+_vm._s(_vm.viewPage.formName)+" "),(_vm.fieldData && _vm.fieldData.Status)?_c('span',{staticStyle:{"font-size":"12px","margin-left":"10px","color":"#fff","border-radius":"4px","padding":"2px 6px"},style:({ background: _vm.statusColor[_vm.fieldData.Status] })},[_vm._v(_vm._s(_vm.statusName[_vm.fieldData.Status]))]):_vm._e()]):_c('el-breadcrumb',{staticStyle:{"margin-left":"10px"},attrs:{"separator":"/"}},[_vm._l((_vm.pageNameList),function(item,index){return _c('el-breadcrumb-item',{key:index,staticStyle:{"cursor":"pointer"},nativeOn:{"click":function($event){return _vm.handleBreadcrumb(item, index)}}},[_c('span',{staticClass:"font-size:20px"},[_vm._v(" "+_vm._s(item.formName)+" ")])])}),(
              _vm.fieldData &&
              _vm.fieldData.Status &&
              _vm.type !== 'add' &&
              _vm.type !== 'copy'
            )?_c('span',{staticStyle:{"font-size":"12px","margin-left":"10px","color":"#fff","border-radius":"4px","padding":"2px 6px"},style:({ background: _vm.statusColor[_vm.fieldData.Status] })},[_vm._v(_vm._s(_vm.statusName[_vm.fieldData.Status]))]):_vm._e()],2),_c('img',{staticStyle:{"width":"125px","height":"36px","margin-right":"16px","margin-left":"auto"},attrs:{"src":"https://ybr-test.oss-cn-shanghai.aliyuncs.com/public/2024/05/15/20240515151741/logo.png","alt":""}}),(_vm.type !== 'add' && _vm.type !== 'copy')?_c('div',{staticStyle:{"margin-left":"auto"}},[_c('el-button',{staticStyle:{"margin-right":"20px"},attrs:{"type":"primary","size":"small"},on:{"click":_vm.handleCopy}},[_vm._v("复制")]),(_vm.btnPermission.includes('PRINT'))?_c('el-button',{staticStyle:{"margin-right":"40px"},attrs:{"type":"primary","size":"small"},on:{"click":_vm.handlePrint}},[_vm._v("打印")]):_vm._e()],1):_vm._e()],1)]},proxy:true}],null,false,1566935970)},[_c('div',{staticClass:"drawerPage"},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","width":"100%","flex":"1","overflow":"hidden"}},[(_vm.type !== 'add' && _vm.type !== 'copy' && _vm.relevanceList.length > 1)?_c('div',{staticStyle:{"width":"98%"}},[_c('ul',{staticClass:"tabs"},_vm._l((_vm.relevanceList),function(item){return _c('li',{key:item.id,staticClass:"tabsItems",class:{
                activeTabItems:
                  _vm.activeTabItems && _vm.activeTabItems.id === item.id,
              },on:{"click":function($event){return _vm.handleTab(item)}}},[_vm._v(" "+_vm._s(item.formName)+" "),_c('span',[_vm._v(" "+_vm._s(item.count || item.count === 0 ? `(${item.count})` : item.count)+" ")])])}),0)]):_vm._e(),_c('div',{staticClass:"content"},[(
              _vm.activeTabItems.id === _vm.fieldData.formId ||
              _vm.type === 'add' ||
              _vm.type === 'copy'
            )?_c('el-form',{directives:[{name:"loading",rawName:"v-loading.fullscreen.lock",value:(_vm.formLoading),expression:"formLoading",modifiers:{"fullscreen":true,"lock":true}}],ref:"ruleForm",attrs:{"model":_vm.formData,"label-position":"top","element-loading-text":"拼命加载中"}},[_c('el-row',_vm._l((_vm.field),function(item,index){return _c('el-col',{directives:[{name:"show",rawName:"v-show",value:(!item.showTable),expression:"!item.showTable"}],key:item.code + index,attrs:{"span":item.parentId ? 12 : 24}},[(
                    item.itemLimit &&
                    item.itemLimit.isShow &&
                    !item.isHide &&
                    item.formId
                  )?_c('el-form-item',{staticStyle:{"margin":"20px 20px"},attrs:{"label":item.title,"label-width":"100px","rules":[
                    {
                      required: item.itemLimit.isRequire,
                      message: '请输入',
                      trigger: ['blur', 'change'],
                    },
                  ],"prop":item.code}},[_c(item.name,{tag:"component",attrs:{"item":item,"formData":_vm.formData,"field":_vm.field,"type":_vm.type},on:{"queryList":_vm.queryList,"update:field":function($event){_vm.field=$event}}})],1):_vm._e()],1)}),1)],1):_c('div',{staticStyle:{"padding":"30px 22px"}},[(_vm.relevanceDetails.search)?_c('el-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.isCollapse),expression:"isCollapse"}],staticClass:"formData",style:({ height: !_vm.isCollapse ? '0px' : '100%' }),attrs:{"model":_vm.tableFormData,"inline":true,"label-position":"left"}},_vm._l((_vm.relevanceDetails.search),function(item){return _c('el-form-item',{key:item.code,staticStyle:{"margin-left":"20px"},attrs:{"label":item.title,"label-width":"100px"},scopedSlots:_vm._u([{key:"label",fn:function(){return [(item.title.length > 6)?_c('el-popover',{attrs:{"placement":"top-start","width":item.title.length * 12,"trigger":"hover","content":item.title}},[_c('div',{staticStyle:{"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis","width":"100px"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" "+_vm._s(item.title)+" ")])]):_c('div',[_vm._v(_vm._s(item.title))])]},proxy:true}],null,true)},[_c(item.name,{tag:"component",attrs:{"item":item,"formData":_vm.tableFormData},on:{"queryList":_vm.tableQueryList}})],1)}),1):_vm._e(),(_vm.relevanceDetails && _vm.relevanceDetails.search)?_c('div',{staticClass:"isCollapseSearch"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":!_vm.isCollapse ? '展开搜索' : '收起搜索',"placement":"top"}},[_c('i',{class:!_vm.isCollapse
                      ? 'el-icon-d-arrow-right'
                      : 'el-icon-d-arrow-left',on:{"click":function($event){_vm.isCollapse = !_vm.isCollapse}}})])],1):_vm._e(),_c('el-button',{attrs:{"type":"primary","size":"small","icon":"el-icon-plus"},on:{"click":_vm.handleTableAdd}},[_vm._v("新增数据")]),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoading),expression:"tableLoading"}],staticStyle:{"margin-top":"10px"},attrs:{"data":_vm.relevanceDetails.data.records,"border":"","row-style":_vm.rowStyle}},[_c('el-table-column',{attrs:{"type":"index","width":"60","align":"center","label":"序号","fixed":""}}),_c('el-table-column',{attrs:{"prop":"Name","label":"数据标题","align":"center","width":"250","fixed":"","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('div',{on:{"click":function($event){return _vm.handleName(row)}}},[_c('span',{staticClass:"caption",staticStyle:{"color":"#409eff"},attrs:{"href":"#"}},[_vm._v(" "+_vm._s(row.Name || "\\")+" ")])])]}}],null,false,1596956278)}),_vm._l((_vm.relevanceDetails.field),function(column){return _c('el-table-column',{key:column.code,attrs:{"label":column.title,"prop":column.code,"show-overflow-tooltip":"","align":"center","width":['Created', 'Updated', 'DateTime'].includes(column.code)
                    ? '180px'
                    : _vm.flexColumnWidth(
                        column.title,
                        _vm.relevanceDetails.data.records,
                        column.code
                      )}})})],2),(_vm.total > 10)?_c('el-pagination',{attrs:{"page-sizes":[10, 20, 50, 100],"page-size":10,"layout":"total, sizes, prev, pager, next","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}}):_vm._e()],1)],1)]),(
          _vm.type !== 'add' &&
          _vm.type !== 'copy' &&
          _vm.activeTabItems.formName === '详情'
        )?_c('div',{staticClass:"process",style:({ width: _vm.open ? '420px' : '80px' })},[(_vm.open)?_c('el-tabs',{staticStyle:{"padding":"0 20px"},on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"流程日志","name":"flowLog"}},[(_vm.flowLogList && _vm.flowLogList.length > 0)?_c('flowLog',{attrs:{"flowLogList":_vm.flowLogList},on:{"handleUrge":_vm.handleUrge}}):_c('el-empty',{attrs:{"description":"暂无数据"}})],1),_c('el-tab-pane',{attrs:{"label":`评论(${_vm.commentList && _vm.commentList.length})`,"name":"comment"}},[_c('comment',{attrs:{"commentList":_vm.commentList},on:{"update:commentList":function($event){_vm.commentList=$event},"update:comment-list":function($event){_vm.commentList=$event}}})],1),_c('el-tab-pane',{attrs:{"label":"操作日志","name":"operateLog"}},[(_vm.operateLogList && _vm.operateLogList.length > 0)?_c('operateLog',{staticStyle:{"padding-bottom":"60px"},attrs:{"operateLogList":_vm.operateLogList}}):_c('el-empty',{attrs:{"description":"暂无数据"}})],1)],1):_c('div',_vm._l((_vm.sidebarIcon),function(item){return _c('div',{key:item.id,staticClass:"tickets",on:{"click":function($event){return _vm.handleOriginal(item.id)}}},[_c('div',[_c('img',{staticStyle:{"width":"48px","height":"48px"},attrs:{"src":item.icon,"alt":""}})]),_c('span',[_vm._v(_vm._s(item.name))])])}),0),_c('div',[_c('div',{class:`arrow-${_vm.open ? 'right' : 'left'}`,style:({ right: _vm.open ? '420px' : '80px' }),on:{"click":_vm.handleOpen}},[(_vm.open)?_c('div',{staticClass:"left"}):_c('div',{staticClass:"right"})])])],1):_vm._e()]),_c('div',{staticClass:"confirmBtn"},[(_vm.isShow)?_c('el-button',{attrs:{"type":"success","size":"small"},on:{"click":function($event){_vm.taskApproveDialog = true;
          _vm.$set(_vm.form, 'comment', '同意');
          _vm.status = 1;}}},[_vm._v("同意")]):_vm._e(),(_vm.isShow)?_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){_vm.taskApproveDialog = true;
          _vm.$set(_vm.form, 'comment', '拒绝');
          _vm.status = 2;}}},[_vm._v("拒绝")]):_vm._e(),(_vm.isShow)?_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){_vm.taskApproveDialog = true;
          _vm.$set(_vm.form, 'comment', '');
          _vm.status = 3;}}},[_vm._v("转交")]):_vm._e(),(
          _vm.type === 'see' &&
          _vm.btnPermission.includes('SUBMIT') &&
          _vm.fieldData &&
          _vm.fieldData.Status === 'Effective' &&
          _vm.activeTabItems &&
          _vm.activeTabItems.id === _vm.fieldData.formId
        )?_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.handleEdit}},[_vm._v("编辑")]):_vm._e(),(
          _vm.type === 'edit' &&
          _vm.btnPermission.includes('DELETE') &&
          _vm.fieldData &&
          _vm.activeTabItems &&
          _vm.activeTabItems.id === _vm.fieldData.formId
        )?_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.handleDel}},[_vm._v("删除")]):_vm._e(),(
          _vm.type === 'add' ||
          _vm.type === 'copy' ||
          (_vm.type !== 'see' &&
            _vm.btnPermission.includes('SUBMIT') &&
            _vm.fieldData &&
            _vm.activeTabItems &&
            _vm.activeTabItems.id === _vm.fieldData.formId)
        )?_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.confirmInfo}},[_vm._v("保存")]):_vm._e()],1)]),_c('el-dialog',{attrs:{"title":"审批","visible":_vm.taskApproveDialog,"width":"600px","modal":false,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.taskApproveDialog=$event},"close":function($event){_vm.form = {}}}},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"90px"}},[_c('el-form-item',{attrs:{"label":`${_vm.status === 2 ? '*' : ''}输入意见：`}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.form.comment),callback:function ($$v) {_vm.$set(_vm.form, "comment", $$v)},expression:"form.comment"}})],1),(_vm.status == 3)?_c('el-form-item',{attrs:{"label":"*转交人员："}},[_c('UserPicker',{tag:"component",attrs:{"formData":_vm.form,"item":{ code: 'deliverId' }}})],1):_vm._e()],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.taskApproveDialog = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.confirmInfo('Approve')}}},[_vm._v("确 定")])],1)],1),_c('printPage',{attrs:{"showPrintPage":_vm.showPrintPage,"field":_vm.field,"formOptionInfo":_vm.fieldData,"pageNameList":_vm.pageNameList,"logList":_vm.logList},on:{"update:showPrintPage":function($event){_vm.showPrintPage=$event},"update:show-print-page":function($event){_vm.showPrintPage=$event}}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
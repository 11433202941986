var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"display":"flex","align-items":"center","margin-bottom":"20px"}},[_c('div',{staticStyle:{"width":"2px","height":"12px","background":"#2e98fe","margin-right":"4px"}}),_c('span',{staticStyle:{"font-size":"12px","color":"#3d3d3d"}},[_vm._v("流程节点")]),(_vm.flowLogList[0].nodeName !== '结束')?_c('el-popover',{staticStyle:{"margin-left":"auto"},attrs:{"placement":"bottom","width":"200","trigger":"click"},model:{value:(_vm.urge),callback:function ($$v) {_vm.urge=$$v},expression:"urge"}},[_c('div',{staticStyle:{"font-size":"12px","color":"#2e98fe","cursor":"pointer"},attrs:{"slot":"reference"},slot:"reference"},[_c('img',{staticStyle:{"width":"16px","height":"16px","vertical-align":"middle"},attrs:{"src":"https://ybr-test.oss-cn-shanghai.aliyuncs.com/public/images/容器@2x (11).png","alt":""}}),_vm._v(" 一键催办 ")]),_c('div',[_c('el-input',{attrs:{"type":"textarea","rows":3},model:{value:(_vm.urgeText),callback:function ($$v) {_vm.urgeText=$$v},expression:"urgeText"}}),_c('div',{staticStyle:{"margin-top":"10px","text-align":"end"}},[_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){_vm.urge = false}}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":_vm.handleUrge}},[_vm._v("确定")])],1)],1)]):_vm._e()],1),_vm._l((_vm.flowLogList),function(item,index){return _c('div',{key:item.nodeCode + index,staticClass:"flowLogNode",style:({
      backgroundColor:
        item.status === 'NO_EXECUTE'
          ? '#FFF3E0'
          : item.status === 'DELIVER'
          ? '#F4F4F4'
          : item.status === 'READED' && item.nodeType === 'END'
          ? '#EEEEEE'
          : item.status === 'READED'
          ? '#F4F4F4'
          : '#EEEEEE',
    })},[_c('div',{staticClass:"icon",style:({
        borderColor: _vm.setBorderColor(item.nodeType, item.status),
      })}),_c('div',{staticClass:"title"},[_c('div',{staticStyle:{"font-size":"12px","width":"fit-content","padding":"2px 4px","border-radius":"4px 4px 4px 4px","border":"1px solid rgba(252, 165, 34, 0.6)","display":"flex","align-items":"center"},style:({
          color: _vm.setBorderColor(item.nodeType, item.status),
          borderColor: _vm.setBorderColor(item.nodeType, item.status),
        })},[_c('span',{staticStyle:{"width":"6px","height":"6px","border-radius":"50%","background":"#2e98fe","margin-right":"4px"},style:({
            backgroundColor: _vm.setBorderColor(item.nodeType, item.status),
          })}),_vm._v(" "+_vm._s(_vm.flowTilteText(item.nodeType, item.status))+" ")]),_c('span',{staticStyle:{"font-size":"16px","font-family":"MiSans-Medium, MiSans","font-weight":"600","color":"#3d3d3d","line-height":"20px","margin-left":"8px"}},[_vm._v(" "+_vm._s(item.nodeName)+" ")]),(!['ROOT', 'END'].includes(item.nodeType))?_c('div',{staticStyle:{"margin-left":"auto","cursor":"pointer"}},[(item.isShowComment)?_c('i',{staticClass:"el-icon-arrow-up",on:{"click":function($event){return _vm.$set(item, 'isShowComment', false)}}}):_c('i',{staticClass:"el-icon-arrow-down",on:{"click":function($event){return _vm.$set(item, 'isShowComment', true)}}})]):_vm._e()]),_vm._l((item.taskList.filter((f) => f.nodeCode !== 'END')),function(info){return _c('div',{key:info.id},[_c('div',{staticClass:"info"},[(info.executorAvatar)?_c('img',{staticClass:"avatar",attrs:{"src":info.executorAvatar,"alt":""}}):_c('span',{staticClass:"avatarText"},[_vm._v(_vm._s(info.executorName ? info.executorName.slice(0, 2) : ""))]),_c('span',{staticStyle:{"margin":"0 8px","font-size":"14px","color":"#888888"}},[_vm._v(_vm._s(info.executorName))]),_c('span',{staticClass:"status",style:({
            color: _vm.setBorderColor(info.nodeType, info.status),
            borderColor: _vm.setBorderColor(info.nodeType, info.status),
          })},[_vm._v(_vm._s(_vm.setFlowText(info.nodeType, info.status)))]),_c('span',{staticClass:"time"},[_vm._v(_vm._s(info.ended))])]),(!item.isShowComment && info.comment)?_c('div',{staticClass:"comment"},[_vm._v(" "+_vm._s(info.comment)+" ")]):_vm._e()])})],2)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
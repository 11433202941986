<template>
  <el-autocomplete
    v-get-selected-value
    v-if="item.type && item.type === 2"
    size="medium"
    v-model="formData[item.code]"
    :fetch-suggestions="remoteMethod"
    placeholder="请输入内容"
    :trigger-on-focus="false"
    @select="queryList"
    @change="queryList"
    style="width: 100%"
    clearable
  ></el-autocomplete>
  <el-input
    v-else
    clearable
    v-model.lazy="formData[item.code]"
    @change="queryList"
    placeholder="请输入内容"
    :disabled="disabled"
  ></el-input>
</template>
<script>
import Minxins from "../Minxins";
import { getCandidateList } from "@/api/process";
export default {
  name: "Name",
  mixins: [Minxins],
  data() {
    return {};
  },
  methods: {
    async remoteMethod(val, cb) {
      let res = await getCandidateList(this.item.formId, "Name", val);
      let arr =
        res.data &&
        res.data.map((item) => {
          return { value: item };
        });
      cb(arr);
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .el-select-dropdown__item {
  max-width: 450px;
  display: -webkit-box;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>

//混入组件数据
export default {
    props: {
        item: {
            default: () => { },
        },
        formData: {
            default: () => { },
        },
        type: {
            type: String
        }
    },
    computed: {
        disabled() {
            return (this.item.itemLimit && !this.item.itemLimit.isWrite) && this.type === 'see' || (this.fieldData.Status === 'Effective' && this.type === 'see');
        },
        fieldData() {
            return this.$store.state.formData.fieldData;
        },
    },
    methods: {
        queryList() {
            this.$emit("queryList");
        },
    },
}

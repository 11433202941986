import TextInput from "./searchComps/components/TextInput.vue"; //单行文本
import TextareaInput from "./searchComps/components/TextInput.vue"; //多行文本
import AmountInput from "./searchComps/components/AmountInput.vue";  //数字输入

import SelectInput from "./searchComps/components/SelectInput.vue";  //单选框
import MultipleSelect from "./searchComps/components/SelectInput.vue";  //多选框
import DateTime from "./searchComps/components/DateTime.vue";  //日期时间点
import ImageUpload from "./searchComps/components/ImageUpload.vue";  //图片上传
import FileUpload from "./searchComps/components/FileUpload.vue";  //文件上传
import UserPicker from "./searchComps/components/UserPicker.vue";  //人员单选
import MultipleUserPicker from "./searchComps/components/UserPicker.vue";  //人员多选
import DeptPicker from "./searchComps/components/DeptPicker.vue";  //部门单选
import MultipleDeptPicker from "./searchComps/components/DeptPicker.vue";  //部门多选
import Relevance from "./searchComps/components/Relevance.vue"; //关联单选
import MultipleRelevance from "./searchComps/components/Relevance.vue";  //关联多选
import Description from "./searchComps/components/Description.vue";  //说明文字
import Status from "./searchComps/components/Status.vue";  //搜索页面状态
import Name from "./searchComps/components/Name.vue";  //搜索页面状态
import City from "./searchComps/components/City.vue";  //搜索页面状态
import Signature from "./searchComps/components/Signature.vue";  //搜索页面状态

// 明细表
import TableList from "./searchComps/components/TableList.vue";

// 系统控件
import SerialNumber from "./searchComps/components/SerialNumber.vue"; //流水号
import CreatedBy from "./searchComps/components/UserPicker.vue"; //创建人
import OwnerId from "./searchComps/components/UserPicker.vue";  //拥有人
import UpdatedBy from "./searchComps/components/UserPicker.vue";  //拥有人
import CreatedDept from "./searchComps/components/DeptPicker.vue"; //所属部门
import OwnerDeptId from "./searchComps/components/DeptPicker.vue"; //所属部门
import Created from "./searchComps/components/DateTime.vue";  //创建时间
import Updated from "./searchComps/components/DateTime.vue";  //修改时间



export default {
  // 基础组件
  TextInput, TextareaInput, AmountInput, SelectInput, MultipleSelect, DateTime, ImageUpload, FileUpload, UserPicker, MultipleUserPicker, DeptPicker, MultipleDeptPicker, Relevance, MultipleRelevance, Description, Status, Name, City, Signature,
  // 扩展组件
  TableList,
  // 系统控件
  SerialNumber, Created, OwnerId, CreatedDept, CreatedBy, Updated, UpdatedBy, OwnerDeptId
}

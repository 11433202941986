var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ ImageUpload: _vm.type === 'see' }},[_c('el-upload',{ref:"upload",class:{
      hideshow:
        (_vm.formData[_vm.item.code] && _vm.formData[_vm.item.code].length >= 5) ||
        _vm.type === 'see',
    },attrs:{"disabled":_vm.disabled,"action":_vm.url,"limit":5,"multiple":true,"on-success":_vm.handleAvatarSuccess,"list-type":"picture-card","file-list":_vm.formData[_vm.item.code],"before-upload":_vm.beforeUpload,"on-preview":_vm.handlePictureCardPreview,"on-remove":_vm.handleRemove,"headers":{
      Authorization: `Bearer ${_vm.$store.getters.token}`,
    }}},[_c('i',{staticClass:"el-icon-plus"})]),_c('el-dialog',{attrs:{"visible":_vm.dialogVisible,"modal":false,"title":"图片预览"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('img',{attrs:{"width":"100%","src":_vm.dialogImageUrl,"alt":""}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }